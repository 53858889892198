import {useFormErrors, useFormValues} from "../../store/features/form/hooks.ts";
import {useCallback} from "react";
import { joinBr } from "../../utils/react.tsx";
import TrueDateInput from "../TrueDateInput.tsx";
import { TrueDate } from "../../utils/dates.ts";
import { FieldSchema, FieldType } from "../../core/form/model.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.BirthDate>,
}

export default function BirthDateField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.BirthDate);
  const [errors, setErrors] = useFormErrors(FieldType.BirthDate);
  const onChange = useCallback((newDate: TrueDate | null) => {
    setValues({value: newDate});
    setErrors([]);
  }, [setValues, setErrors])

  return (
    <TrueDateInput
      placeholder="__.__.____"
      label="Дата рождения"
      withAsterisk={fieldSchema.required}
      value={values.value}
      onChange={onChange}
      error={joinBr(errors)}
    />
  );
}
