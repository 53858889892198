import {ResponseContext, ValidationErrorEnum} from "../../client";

interface HTTPErrorDetail {
  status: number,
  text: string,
  json: unknown,
}

export class HTTPError extends Error {
  name = "App:HTTPError"
  detail: HTTPErrorDetail

  constructor(detail: HTTPErrorDetail) {
    super(`HTTP ${detail.status}: ${detail.text}`);
    this.detail = detail;
  }

  asValidationError<R>(parse: (json: unknown) => R): R | undefined {
    const json = this.detail.json;
    if (typeof json !== "object" || json === null || !('type' in json)) {
      return undefined;
    }

    if (json.type !== ValidationErrorEnum.ValidationError) {
      return undefined;
    }

    return parse(json);
  }
}

export function isHTTPError(error: unknown): error is HTTPError {
  return error instanceof Error && error.name === "App:HTTPError";
}

export async function makeHTTPError(context: ResponseContext): Promise<HTTPError> {
  const text = await context.response.text();
  let json: unknown;
  try {
    json = JSON.parse(text);
  } catch (e) {
    json = undefined;
  }

  return new HTTPError({
    status: context.response.status,
    text,
    json,
  });
}
