/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Поле фото главного разворота паспорта.
 * @export
 * @interface PassportBiodataPhotoRequest
 */
export interface PassportBiodataPhotoRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PassportBiodataPhotoRequest
     */
    file: boolean;
}

/**
 * Check if a given object implements the PassportBiodataPhotoRequest interface.
 */
export function instanceOfPassportBiodataPhotoRequest(value: object): value is PassportBiodataPhotoRequest {
    if (!('file' in value) || value['file'] === undefined) return false;
    return true;
}

export function PassportBiodataPhotoRequestFromJSON(json: any): PassportBiodataPhotoRequest {
    return PassportBiodataPhotoRequestFromJSONTyped(json, false);
}

export function PassportBiodataPhotoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportBiodataPhotoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function PassportBiodataPhotoRequestToJSON(value?: PassportBiodataPhotoRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': value['file'],
    };
}

