/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Тип населенного пункта.
 * @export
 * @interface SettlementType
 */
export interface SettlementType {
    /**
     * 
     * @type {number}
     * @memberof SettlementType
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementType
     */
    abbr: string;
}

/**
 * Check if a given object implements the SettlementType interface.
 */
export function instanceOfSettlementType(value: object): value is SettlementType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('abbr' in value) || value['abbr'] === undefined) return false;
    return true;
}

export function SettlementTypeFromJSON(json: any): SettlementType {
    return SettlementTypeFromJSONTyped(json, false);
}

export function SettlementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'abbr': json['abbr'],
    };
}

export function SettlementTypeToJSON(value?: Omit<SettlementType, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'abbr': value['abbr'],
    };
}

