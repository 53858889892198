import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { Provider, RegistrationApi } from "../../../../client";
import { clientConfig } from "../../../../core/api/client";
import { FormState, FormSliceState } from "../reducer";
import { setFailed, setLoaded, setLoading } from "../../../../core/api/loadable";
import { newStepStateMap } from "../../../../core/form/steps";
import { getFormContext } from "../../../../core/form/render-context";
import { emptyFormErrors, emptyFormValues } from "../../../../core/form/utils";

export interface SelectProviderPayload {
  provider: Provider,
}

const selectProvider = createAsyncThunk<FormState, SelectProviderPayload>(
  "form/selectProvider",
  async (payload): Promise<FormState> => {
    const api = new RegistrationApi(clientConfig);
    const schema = await api.registrationSchemaRetrieve({providerName: payload.provider.name});
    const formContext = getFormContext(schema.schema);
    return {
      provider: payload.provider,
      schema: schema,
      currentStep: formContext.stepContexts[0].step.key,
      stepStates: newStepStateMap(),
      values: emptyFormValues(),
      errors: emptyFormErrors(),
    };
  },
);
export default selectProvider;

export function addSelectProviderCases(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addCase(selectProvider.pending, state => {
    setLoading(state.formState);
  });
  builder.addCase(selectProvider.fulfilled, (state, action) => {
    setLoaded(state.formState, action.payload);
  });
  builder.addCase(selectProvider.rejected, (state, action) => {
    /* eslint-disable-next-line no-console */
    console.error("selectProvider rejected", action.error);
    setFailed(state.formState, "Не удалось подготовить форму регистрации. Попробуйте обновить страницу.");
  });
}
