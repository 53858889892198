import {Stack, rem, useMatches} from '@mantine/core';
import ContentTitle from "../components/ContentTitle.tsx";
import StartBlock from "../components/StartBlock.tsx";
import AppLayout from "../components/AppLayout.tsx";
import { getSiteSettings } from '../core/site-settings.ts';
import ContentHTML from '../components/ContentHTML.tsx';

const defaultHeader = "Само-регистрация сим-карты";

export default function IndexPage() {
  const siteSettings = getSiteSettings();
  const m24_32 = useMatches({
    base: rem(24),
    md: rem(32),
  });

  return (
    <AppLayout>
      <AppLayout.Card variant="content">
        <ContentTitle mb={rem(24)}>{siteSettings.landing_header || defaultHeader}</ContentTitle>
        <Stack gap={rem(16)} mb={m24_32}>
          {siteSettings.landing_content.map((block, ix) => {
            if (block.block_type === "rich_text") {
              return (
                <ContentHTML key={ix} dangerouslySetInnerHTML={{__html: block.value}}/>
              );
            } else {
              // eslint-disable-next-line no-console
              console.warn(`Content block of type ${block.block_type} isn't supported.`);
            }
          })}
        </Stack>
        <StartBlock/>
      </AppLayout.Card>
    </AppLayout>
  );
}
