import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {
  BuildingType,
  Country,
  Provider,
  RegistrationSchema,
  ResidentialUnitType,
  SettlementType,
  StreetType,
} from "../../../client";
import { FormErrors } from '../../../core/form/errors';
import { FormValues } from '../../../core/form/values';
import { FormStepKey, StepStateMap } from '../../../core/form/steps';
import { Loadable, newLoadable } from '../../../core/api/loadable';
import { addSelectProviderCases } from './thunks/select-provider';
import { addValidateStepCases, addValidateStepMatchers } from './thunks/validate-step';
import { addFetchReferencesCases } from './thunks/fetch-references';
import { getFormContext, getStepContextBefore } from '../../../core/form/render-context';
import { addSubmitCases, addSubmitMatchers } from './thunks/submit';

export interface FormState {
  provider: Provider,
  schema: RegistrationSchema,
  currentStep: FormStepKey,
  stepStates: StepStateMap,
  values: FormValues,
  errors: FormErrors,
}

export interface References {
  countries: Country[],
  settlementTypes: SettlementType[],
  streetTypes: StreetType[],
  buildingTypes: BuildingType[],
  residentialUnitTypes: ResidentialUnitType[],
}

export const initialReferences: References = {
  countries: [],
  settlementTypes: [],
  streetTypes: [],
  buildingTypes: [],
  residentialUnitTypes: [],
};

export interface FormSliceState {
  references: Loadable<References>,
  formState: Loadable<FormState>,
}

const initialState: FormSliceState = {
  references: newLoadable(initialReferences),
  formState: newLoadable(),
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    prevStep: state => {
      if (!state.formState.value) {
        return;
      }

      const formState = state.formState.value;
      const formContext = getFormContext(formState.schema.schema);
      const prevStepCtx = getStepContextBefore(formContext, formState.currentStep);
      if (prevStepCtx) {
        formState.currentStep = prevStepCtx.step.key;
      }
    },
    goToStep: (state, action: PayloadAction<{step: FormStepKey}>) => {
      if (!state.formState.value) {
        return;
      }

      const formState = state.formState.value;
      const formContext = getFormContext(formState.schema.schema);
      const containsStep = formContext.stepContexts.some(ctx => ctx.step.key === action.payload.step);
      if (!containsStep) {
        // eslint-disable-next-line no-console
        console.warn("goToStep called with an unavailable step key.");
        return;
      }
      formState.currentStep = action.payload.step;
    },
    updateValues: (state, action: PayloadAction<Partial<FormValues>>) => {
      const formState = state.formState.value;
      if (!formState) {
        return;
      }

      formState.values = {...formState.values, ...action.payload};
    },
    updateErrors: (state, action: PayloadAction<Partial<FormErrors>>) => {
      const formState = state.formState.value;
      if (!formState) {
        return;
      }

      formState.errors = {...formState.errors, ...action.payload};
    },
    clearFormState: state => {
      state.formState = {state: "notloaded", value: null};
    },
  },
  extraReducers: builder => {
    addFetchReferencesCases(builder);
    addSelectProviderCases(builder);
    addValidateStepCases(builder);
    addSubmitCases(builder);

    addValidateStepMatchers(builder);
    addSubmitMatchers(builder);
  },
});

export const {prevStep, goToStep, updateValues, updateErrors, clearFormState} = formSlice.actions;

export default formSlice.reducer;
