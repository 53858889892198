/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldState } from './FieldState';
import {
    FieldStateFromJSON,
    FieldStateFromJSONTyped,
    FieldStateToJSON,
} from './FieldState';

/**
 * Схема passport_group.
 * @export
 * @interface PassportGroupSchema
 */
export interface PassportGroupSchema {
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    series: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    number: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    issued_by: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    issue_date: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    subdivision_code: FieldState;
    /**
     * 
     * @type {boolean}
     * @memberof PassportGroupSchema
     */
    foreign_enabled: boolean;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    foreign_citizenship_country: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    foreign_number: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    foreign_issued_by: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    foreign_issue_date: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof PassportGroupSchema
     */
    foreign_expiration_date: FieldState;
}

/**
 * Check if a given object implements the PassportGroupSchema interface.
 */
export function instanceOfPassportGroupSchema(value: object): value is PassportGroupSchema {
    if (!('series' in value) || value['series'] === undefined) return false;
    if (!('number' in value) || value['number'] === undefined) return false;
    if (!('issued_by' in value) || value['issued_by'] === undefined) return false;
    if (!('issue_date' in value) || value['issue_date'] === undefined) return false;
    if (!('subdivision_code' in value) || value['subdivision_code'] === undefined) return false;
    if (!('foreign_enabled' in value) || value['foreign_enabled'] === undefined) return false;
    if (!('foreign_citizenship_country' in value) || value['foreign_citizenship_country'] === undefined) return false;
    if (!('foreign_number' in value) || value['foreign_number'] === undefined) return false;
    if (!('foreign_issued_by' in value) || value['foreign_issued_by'] === undefined) return false;
    if (!('foreign_issue_date' in value) || value['foreign_issue_date'] === undefined) return false;
    if (!('foreign_expiration_date' in value) || value['foreign_expiration_date'] === undefined) return false;
    return true;
}

export function PassportGroupSchemaFromJSON(json: any): PassportGroupSchema {
    return PassportGroupSchemaFromJSONTyped(json, false);
}

export function PassportGroupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportGroupSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'series': FieldStateFromJSON(json['series']),
        'number': FieldStateFromJSON(json['number']),
        'issued_by': FieldStateFromJSON(json['issued_by']),
        'issue_date': FieldStateFromJSON(json['issue_date']),
        'subdivision_code': FieldStateFromJSON(json['subdivision_code']),
        'foreign_enabled': json['foreign_enabled'],
        'foreign_citizenship_country': FieldStateFromJSON(json['foreign_citizenship_country']),
        'foreign_number': FieldStateFromJSON(json['foreign_number']),
        'foreign_issued_by': FieldStateFromJSON(json['foreign_issued_by']),
        'foreign_issue_date': FieldStateFromJSON(json['foreign_issue_date']),
        'foreign_expiration_date': FieldStateFromJSON(json['foreign_expiration_date']),
    };
}

export function PassportGroupSchemaToJSON(value?: PassportGroupSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'series': FieldStateToJSON(value['series']),
        'number': FieldStateToJSON(value['number']),
        'issued_by': FieldStateToJSON(value['issued_by']),
        'issue_date': FieldStateToJSON(value['issue_date']),
        'subdivision_code': FieldStateToJSON(value['subdivision_code']),
        'foreign_enabled': value['foreign_enabled'],
        'foreign_citizenship_country': FieldStateToJSON(value['foreign_citizenship_country']),
        'foreign_number': FieldStateToJSON(value['foreign_number']),
        'foreign_issued_by': FieldStateToJSON(value['foreign_issued_by']),
        'foreign_issue_date': FieldStateToJSON(value['foreign_issue_date']),
        'foreign_expiration_date': FieldStateToJSON(value['foreign_expiration_date']),
    };
}

