import {TextInput} from "@mantine/core";
import {useFormErrors, useFormValues} from "../../store/features/form/hooks.ts";
import {ChangeEvent, useCallback} from "react";
import { joinBr } from "../../utils/react.tsx";
import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { FieldSchema, FieldType } from "../../core/form/model.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.Puk>,
}

const maskOptions: MaskitoOptions = {
  mask: /^\d{0,8}$/,
}

export default function PukField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.Puk);
  const [errors, setErrors] = useFormErrors(FieldType.Puk);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({value: event.target.value});
    setErrors([]);
  }, [setValues, setErrors]);

  const inputRef = useMaskito({options: maskOptions});

  return (
    <TextInput
      label="PUK-код"
      description="8 цифр"
      withAsterisk={fieldSchema.required}
      value={values.value || ""}
      onInput={onChange}
      error={joinBr(errors)}
      ref={inputRef}
      inputMode="numeric"
    />
  );
}
