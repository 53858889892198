import { FormDataRequest, FormSchema } from "../../client";
import { FieldErrors } from "./errors";
import { serializeIccid, validateIccid } from "./field-types/iccid";
import { RegistrationRequest } from "../api/types";
import { FormStepKey } from "./steps";
import { emptyFileValues, emptyPrimitiveErrors, emptyPrimitiveValues, isNestedErrorsEmpty, isPrimitiveErrorsEmpty, renderFileSummary, renderPrimitiveSummary, requireFile, requirePrimitive, validateNoop, validatePhoneNumber } from "./field-types/base";
import { serializeSimNumber } from "./field-types/sim-number";
import { serializePuk, validatePuk } from "./field-types/puk";
import { emptyFullNameGroupErrors, emptyFullNameGroupValues, renderFullNameGroupSummary, requireFullNameGroup, serializeFullNameGroup } from "./field-types/full-name-group";
import { emptyGenderValues, renderGenderSummary, requireGender, serializeGender } from "./field-types/gender";
import IccIdField from "../../components/form-fields/IccIdField";
import SimNumberField from "../../components/form-fields/SimNumberField";
import PukField from "../../components/form-fields/PukField";
import FullNameFieldGroup from "../../components/form-fields/FullNameFieldGroup";
import GenderField from "../../components/form-fields/GenderField";
import { emptyBirthDateValues, renderBirthDateSummary, requireBirthDate, serializeBirthDate } from "./field-types/birth-date";
import BirthDateField from "../../components/form-fields/BirthDateField";
import { serializeBirthPlace } from "./field-types/birth-place";
import BirthPlaceField from "../../components/form-fields/BirthPlaceField";
import { FieldValues } from "./values";
import { emptyFullAddressGroupErrors, emptyFullAddressGroupValues, renderFullAddressGroupSummary, requireFullAddressGroup, serializeFullAddressGroup, validateFullAddressGroup } from "./field-types/full-address-group";
import FullAddressFieldGroup from "../../components/form-fields/FullAddressFieldGroup";
import { emptyPassportGroupErrors, emptyPassportGroupValues, renderPassportGroupSummary, requirePassportGroup, serializePassportGroup, validatePassportGroup } from "./field-types/passport-group";
import { serializePassportBiodataPhoto } from "./field-types/passport-biodata-photo";
import { serializePassportAddressPhoto } from "./field-types/passport-address-photo";
import PassportBiodataPhotoField from "../../components/form-fields/PassportBiodataPhotoField";
import PassportAddressPhotoField from "../../components/form-fields/PassportAddressPhotoField";
import { serializeContactPhone } from "./field-types/contact-phone";
import { serializeContactEmail, validateContactEmail } from "./field-types/contact-email";
import ContactPhoneField from "../../components/form-fields/ContactPhoneField";
import ContactEmailField from "../../components/form-fields/ContactEmailField";
import { AssertKeysEqual } from "../../utils/types";
import { References } from "../../store/features/form/reducer";
import PassportFieldGroup from "../../components/form-fields/PassportFieldGroup";

export enum FieldType {
  Iccid = "iccid",
  SimNumber = "sim_number",
  Puk = "puk",
  FullNameGroup = "full_name_group",
  Gender = "gender",
  BirthDate = "birth_date",
  BirthPlace = "birth_place",
  FullAddressGroup = "full_address_group",
  PassportGroup = "passport_group",
  PassportBiodataPhoto = "passport_biodata_photo",
  PassportAddressPhoto = "passport_address_photo",
  ContactPhone = "contact_phone",
  ContactEmail = "contact_email",
}

// Упадет, если FieldType не совпадает с ключами FormSchema и FormDataRequest.
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
type MatchesSchemaKeys = AssertKeysEqual<Record<FieldType, unknown>, FormSchema>;
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
type MatchesDataKeys = AssertKeysEqual<Record<FieldType, unknown>, Record<keyof FormDataRequest, unknown>>;

export type FieldSchema<K extends FieldType> = NonNullable<FormSchema[K]>;

export interface SummaryItem {key: string, label: string, value: string | null, hasErrors: boolean}

export interface FieldModel<K extends FieldType> {
  type: K,
  step: FormStepKey,
  order: number,
  emptyValues: () => FieldValues<K>,
  emptyErrors: () => FieldErrors<K>,
  isErrorsEmpty: (errors: FieldErrors<K>) => boolean,
  require: (schema: FieldSchema<K>, values: FieldValues<K>) => FieldErrors<K>,
  validate: (schema: FieldSchema<K>, values: FieldValues<K>) => FieldErrors<K>,
  serialize: (values: FieldValues<K>, request: RegistrationRequest) => void,
  renderComponent: React.FunctionComponent<{fieldSchema: FieldSchema<K>}>,
  renderSummary: (schema: FieldSchema<K>, values: FieldValues<K>, errors: FieldErrors<K>, references: References) => SummaryItem[]
}

export const FormModel: {[K in FieldType]: FieldModel<K>} = {
  [FieldType.Iccid]: {
    type: FieldType.Iccid,
    step: FormStepKey.SimParams,
    order: 1,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validateIccid,
    serialize: serializeIccid,
    renderComponent: IccIdField,
    renderSummary: renderPrimitiveSummary("ICCID"),
  },
  [FieldType.SimNumber]: {
    type: FieldType.SimNumber,
    step: FormStepKey.SimParams,
    order: 2,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validatePhoneNumber,
    serialize: serializeSimNumber,
    renderComponent: SimNumberField,
    renderSummary: renderPrimitiveSummary("Абонентский номер"),
  },
  [FieldType.Puk]: {
    type: FieldType.Puk,
    step: FormStepKey.SimParams,
    order: 3,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validatePuk,
    serialize: serializePuk,
    renderComponent: PukField,
    renderSummary: renderPrimitiveSummary("PUK"),
  },
  [FieldType.FullNameGroup]: {
    type: FieldType.FullNameGroup,
    step: FormStepKey.BioData,
    order: 4,
    emptyValues: emptyFullNameGroupValues,
    emptyErrors: emptyFullNameGroupErrors,
    isErrorsEmpty: isNestedErrorsEmpty,
    require: requireFullNameGroup,
    validate: validateNoop<FieldType.FullNameGroup>(emptyFullNameGroupErrors),
    serialize: serializeFullNameGroup,
    renderComponent: FullNameFieldGroup,
    renderSummary: renderFullNameGroupSummary,
  },
  [FieldType.Gender]: {
    type: FieldType.Gender,
    step: FormStepKey.BioData,
    order: 5,
    emptyValues: emptyGenderValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requireGender,
    validate: validateNoop<FieldType.Gender>(emptyPrimitiveErrors),
    serialize: serializeGender,
    renderComponent: GenderField,
    renderSummary: renderGenderSummary,
  },
  [FieldType.BirthDate]: {
    type: FieldType.BirthDate,
    step: FormStepKey.BioData,
    order: 6,
    emptyValues: emptyBirthDateValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requireBirthDate,
    validate: validateNoop<FieldType.BirthDate>(emptyPrimitiveErrors),
    serialize: serializeBirthDate,
    renderComponent: BirthDateField,
    renderSummary: renderBirthDateSummary,
  },
  [FieldType.BirthPlace]: {
    type: FieldType.BirthPlace,
    step: FormStepKey.BioData,
    order: 7,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validateNoop<FieldType.BirthPlace>(emptyPrimitiveErrors),
    serialize: serializeBirthPlace,
    renderComponent: BirthPlaceField,
    renderSummary: renderPrimitiveSummary("Место рождения"),
  },
  [FieldType.FullAddressGroup]: {
    type: FieldType.FullAddressGroup,
    step: FormStepKey.Address,
    order: 8,
    emptyValues: emptyFullAddressGroupValues,
    emptyErrors: emptyFullAddressGroupErrors,
    isErrorsEmpty: isNestedErrorsEmpty,
    require: requireFullAddressGroup,
    validate: validateFullAddressGroup,
    serialize: serializeFullAddressGroup,
    renderComponent: FullAddressFieldGroup,
    renderSummary: renderFullAddressGroupSummary,
  },
  [FieldType.PassportGroup]: {
    type: FieldType.PassportGroup,
    step: FormStepKey.Passport,
    order: 9,
    emptyValues: emptyPassportGroupValues,
    emptyErrors: emptyPassportGroupErrors,
    isErrorsEmpty: isNestedErrorsEmpty,
    require: requirePassportGroup,
    validate: validatePassportGroup,
    serialize: serializePassportGroup,
    renderComponent: PassportFieldGroup,
    renderSummary: renderPassportGroupSummary,
  },
  [FieldType.PassportBiodataPhoto]: {
    type: FieldType.PassportBiodataPhoto,
    step: FormStepKey.Passport,
    order: 10,
    emptyValues: emptyFileValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requireFile,
    validate: validateNoop<FieldType.PassportBiodataPhoto>(emptyPrimitiveErrors),
    serialize: serializePassportBiodataPhoto,
    renderComponent: PassportBiodataPhotoField,
    renderSummary: renderFileSummary("Фото главного разворота паспорта"),
  },
  [FieldType.PassportAddressPhoto]: {
    type: FieldType.PassportAddressPhoto,
    step: FormStepKey.Passport,
    order: 11,
    emptyValues: emptyFileValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requireFile,
    validate: validateNoop<FieldType.PassportAddressPhoto>(emptyPrimitiveErrors),
    serialize: serializePassportAddressPhoto,
    renderComponent: PassportAddressPhotoField,
    renderSummary: renderFileSummary("Фото разворота с текущей пропиской"),
  },
  [FieldType.ContactPhone]: {
    type: FieldType.ContactPhone,
    step: FormStepKey.Confirm,
    order: 12,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validatePhoneNumber,
    serialize: serializeContactPhone,
    renderComponent: ContactPhoneField,
    renderSummary: renderPrimitiveSummary("Телефон для связи"),
  },
  [FieldType.ContactEmail]: {
    type: FieldType.ContactEmail,
    step: FormStepKey.Confirm,
    order: 13,
    emptyValues: emptyPrimitiveValues,
    emptyErrors: emptyPrimitiveErrors,
    isErrorsEmpty: isPrimitiveErrorsEmpty,
    require: requirePrimitive,
    validate: validateContactEmail,
    serialize: serializeContactEmail,
    renderComponent: ContactEmailField,
    renderSummary: renderPrimitiveSummary("Email"),
  },
};
