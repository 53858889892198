/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Простая схема, у которой единственный определяющий параметр - обязательность.
 * @export
 * @interface BasicFieldSchema
 */
export interface BasicFieldSchema {
    /**
     * 
     * @type {boolean}
     * @memberof BasicFieldSchema
     */
    required: boolean;
}

/**
 * Check if a given object implements the BasicFieldSchema interface.
 */
export function instanceOfBasicFieldSchema(value: object): value is BasicFieldSchema {
    if (!('required' in value) || value['required'] === undefined) return false;
    return true;
}

export function BasicFieldSchemaFromJSON(json: any): BasicFieldSchema {
    return BasicFieldSchemaFromJSONTyped(json, false);
}

export function BasicFieldSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicFieldSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'required': json['required'],
    };
}

export function BasicFieldSchemaToJSON(value?: BasicFieldSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'required': value['required'],
    };
}

