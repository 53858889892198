import {Group, Radio} from "@mantine/core";
import {useFormErrors, useFormValues} from "../../store/features/form/hooks.ts";
import {useCallback} from "react";
import { joinBr } from "../../utils/react.tsx";
import { FieldSchema, FieldType } from "../../core/form/model.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.Gender>,
}

export default function GenderField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.Gender);
  const [errors, setErrors] = useFormErrors(FieldType.Gender);
  const onChange = useCallback((newChoice: string) => {
    if (newChoice === "M" || newChoice === "F") {
      setValues({choice: newChoice});
    } else {
      setValues({choice: null});
    }
    setErrors([]);
  }, [setValues, setErrors]);

  return (
    <Radio.Group
      label="Пол"
      withAsterisk={fieldSchema.required}
      value={values.choice}
      onChange={onChange}
      error={joinBr(errors)}
    >
      <Group mt="xs">
        <Radio value="M" label="Мужской"/>
        <Radio value="F" label="Женский"/>
      </Group>
    </Radio.Group>
  );
}
