import { configureStore } from '@reduxjs/toolkit'
import formReducer from "./features/form/reducer.ts";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: {
    form: formReducer,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AsyncThunkConfig = {
  dispatch: AppDispatch,
  state: RootState,
};
