/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Поле фото разворота паспорта с адресом.
 * @export
 * @interface PassportAddressPhotoRequest
 */
export interface PassportAddressPhotoRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PassportAddressPhotoRequest
     */
    file: boolean;
}

/**
 * Check if a given object implements the PassportAddressPhotoRequest interface.
 */
export function instanceOfPassportAddressPhotoRequest(value: object): value is PassportAddressPhotoRequest {
    if (!('file' in value) || value['file'] === undefined) return false;
    return true;
}

export function PassportAddressPhotoRequestFromJSON(json: any): PassportAddressPhotoRequest {
    return PassportAddressPhotoRequestFromJSONTyped(json, false);
}

export function PassportAddressPhotoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportAddressPhotoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function PassportAddressPhotoRequestToJSON(value?: PassportAddressPhotoRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': value['file'],
    };
}

