/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Группа полей с паспортными данными.
 * @export
 * @interface PassportGroupRequest
 */
export interface PassportGroupRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PassportGroupRequest
     */
    foreign_citizenship?: boolean;
    /**
     * ISO 3166-1 alpha-2.
     * @type {string}
     * @memberof PassportGroupRequest
     */
    citizenship_country: string | null;
    /**
     * 
     * @type {string}
     * @memberof PassportGroupRequest
     */
    series: string | null;
    /**
     * 
     * @type {string}
     * @memberof PassportGroupRequest
     */
    number: string | null;
    /**
     * 
     * @type {string}
     * @memberof PassportGroupRequest
     */
    issued_by: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportGroupRequest
     */
    issue_date: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportGroupRequest
     */
    expiration_date: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PassportGroupRequest
     */
    subdivision_code: string | null;
}

/**
 * Check if a given object implements the PassportGroupRequest interface.
 */
export function instanceOfPassportGroupRequest(value: object): value is PassportGroupRequest {
    if (!('citizenship_country' in value) || value['citizenship_country'] === undefined) return false;
    if (!('series' in value) || value['series'] === undefined) return false;
    if (!('number' in value) || value['number'] === undefined) return false;
    if (!('issued_by' in value) || value['issued_by'] === undefined) return false;
    if (!('issue_date' in value) || value['issue_date'] === undefined) return false;
    if (!('expiration_date' in value) || value['expiration_date'] === undefined) return false;
    if (!('subdivision_code' in value) || value['subdivision_code'] === undefined) return false;
    return true;
}

export function PassportGroupRequestFromJSON(json: any): PassportGroupRequest {
    return PassportGroupRequestFromJSONTyped(json, false);
}

export function PassportGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportGroupRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'foreign_citizenship': json['foreign_citizenship'] == null ? undefined : json['foreign_citizenship'],
        'citizenship_country': json['citizenship_country'],
        'series': json['series'],
        'number': json['number'],
        'issued_by': json['issued_by'],
        'issue_date': (json['issue_date'] == null ? null : new Date(json['issue_date'])),
        'expiration_date': (json['expiration_date'] == null ? null : new Date(json['expiration_date'])),
        'subdivision_code': json['subdivision_code'],
    };
}

export function PassportGroupRequestToJSON(value?: PassportGroupRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'foreign_citizenship': value['foreign_citizenship'],
        'citizenship_country': value['citizenship_country'],
        'series': value['series'],
        'number': value['number'],
        'issued_by': value['issued_by'],
        'issue_date': (value['issue_date'] == null ? null : (value['issue_date'] as any).toISOString().substring(0,10)),
        'expiration_date': (value['expiration_date'] == null ? null : (value['expiration_date'] as any).toISOString().substring(0,10)),
        'subdivision_code': value['subdivision_code'],
    };
}

