import { FieldSchema, FieldType } from "../model";
import { FieldErrors } from "../errors";
import { RegistrationRequest } from "../../api/types";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.Puk>;
type Values = FieldValues<FieldType.Puk>;
type Errors = FieldErrors<FieldType.Puk>;

export function serializePuk(values: Values, request: RegistrationRequest) {
    request.body.puk = values;
}

export function validatePuk(schema: Schema, {value}: Values): Errors {
    if (!value) return [];

    if (!/^\d{8}$/.test(value)) {
        return ["PUK-код должен состоять из 8 цифр."];
    }

    return [];
}
