import { References } from "../../../store/features/form/reducer";
import { onlyDigits } from "../../../utils/strings";
import { ErrorMsg, FieldErrors } from "../errors";
import { FieldType, SummaryItem } from "../model";

export function emptyPrimitiveValues(): {value: string} {
    return {value: ""};
}

export function emptyFileValues(): {fileId: null} {
    return {fileId: null};
}

export function emptyPrimitiveErrors(): string[] {
    return [];
}

export function isPrimitiveErrorsEmpty(errors: string[]): boolean {
    return errors.length === 0;
}

export function isNestedErrorsEmpty(errors: Record<string, string[]>): boolean {
    return Object.values(errors).every(l => l.length === 0);
}

export function requirePrimitive({required}: {required: boolean}, {value}: {value: string | null}): string[] {
    if (required && !value) {
        return [ErrorMsg.required];
    }
    return [];
}

export function requireFile({required}: {required: boolean}, {fileId}: {fileId: string | null}): string[] {
    if (required && !fileId) {
        return [ErrorMsg.required];
    }
    return [];
}

export function validatePhoneNumber(schema: unknown, {value}: {value: string | null}): string[] {
    if (!value) return [];

    const startsWithPlus7 = value.startsWith("+7");
    const contains11Digits = onlyDigits(value).length === 11;
    if (!startsWithPlus7 || !contains11Digits) {
        return ["Введите действительный номер телефона."];
    }

    return [];
}

export function validateNoop<K extends FieldType>(emptyErrors: () => FieldErrors<K>): (schema: unknown, values: unknown)=> FieldErrors<K> {
    return () => emptyErrors();
}

export function renderPrimitiveSummary(label: string): (schema: unknown, values: {value: string | null}, errors: string[], references: References) => SummaryItem[] {
    return (schema, values, errors) => [{key: "value", label: label, value: values.value, hasErrors: errors.length > 0}];
}

export function renderFileSummary(label: string): (schema: unknown, values: {fileId: string | null}, errors: string[], references: References) => SummaryItem[] {
    return (schema, values, errors) => [{key: "file", label: label, value: values.fileId ? "Заполнено" : null, hasErrors: errors.length > 0}];
}
