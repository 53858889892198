import {TextInput} from "@mantine/core";
import { ChangeEvent, useCallback } from "react";
import { useFormErrors, useFormValues } from "../../store/features/form/hooks.ts";
import { joinBr } from "../../utils/react.tsx";
import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { iccidMask } from "../../utils/masks.ts";
import { FieldSchema, FieldType } from "../../core/form/model.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.Iccid>,
}

const maskOptions: MaskitoOptions = {
  mask: iccidMask,
}

export default function IccIdField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.Iccid);
  const [errors, setErrors] = useFormErrors(FieldType.Iccid);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({value: event.target.value});
    setErrors([]);
  }, [setValues, setErrors]);

  const inputRef = useMaskito({options: maskOptions});

  return (
    <TextInput
      label="ICCID сим-карты"
      description="От 17 до 20 цифр, начинается с 89. Обычно напечатан на сим-карте."
      placeholder="89__ ____ ____ ____ ___-_"
      withAsterisk={fieldSchema.required}
      value={values.value || ""}
      onInput={onChange}
      error={joinBr(errors)}
      ref={inputRef}
      inputMode="numeric"
    />
  );
}
