import AppLayout from "../components/AppLayout.tsx";
import {useLoaderData} from "react-router-dom";
import {Provider} from "../client";
import {useAppDispatch, useAppSelector} from "../store";
import {useEffect} from "react";
import ErrorAlert from "../components/ErrorAlert.tsx";
import FormStepCard from "../components/FormStepCard.tsx";
import selectProvider from "../store/features/form/thunks/select-provider.ts";
import SelectedProviderCard from "../components/SelectedProviderCard.tsx";
import fetchReferences from "../store/features/form/thunks/fetch-references.ts";
import FormStepCardSkeleton from "../components/FormStepCardSkeleton.tsx";

export interface RegisterProviderLoaderData {
  provider: Provider,
}

export default function RegisterProviderPage() {
  const loaderData = useLoaderData() as RegisterProviderLoaderData;
  const loadableReferences = useAppSelector(state => state.form.references);
  const loadableFormState = useAppSelector(state => state.form.formState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadableReferences.state === "notloaded") {
      dispatch(fetchReferences());
    }
  }, [loadableReferences.state, dispatch]);

  useEffect(() => {
    dispatch(selectProvider({provider: loaderData.provider}));
  }, [dispatch, loaderData]);

  const anyLoading = (
    loadableFormState.state === "notloaded" ||
    loadableFormState.state === "loading" ||
    loadableReferences.state === "notloaded" ||
    loadableReferences.state === "loading"
  );
  if (anyLoading) {
    return (
      <AppLayout>
        <FormStepCardSkeleton provider={loaderData.provider}/>
      </AppLayout>
    );
  }

  if (loadableReferences.state === "failed") {
    return (
      <AppLayout>
        <SelectedProviderCard provider={loaderData.provider} switchLink variant="content">
          <ErrorAlert>{loadableReferences.message}</ErrorAlert>
        </SelectedProviderCard>
      </AppLayout>
    );
  }

  if (loadableFormState.state === "failed") {
    return (
      <AppLayout>
        <SelectedProviderCard provider={loaderData.provider} switchLink variant="content">
          <ErrorAlert>{loadableFormState.message}</ErrorAlert>
        </SelectedProviderCard>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <FormStepCard/>
    </AppLayout>
  );
}
