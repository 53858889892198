import { formatTrueDate, toUTCDate } from "../../../utils/dates";
import { RegistrationRequest } from "../../api/types";
import { ErrorMsg, FieldErrors } from "../errors";
import { FieldSchema, FieldType, SummaryItem } from "../model";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.BirthDate>;
type Values = FieldValues<FieldType.BirthDate>;
type Errors = FieldErrors<FieldType.BirthDate>;

export function emptyBirthDateValues(): Values {
    return {value: null};
}

export function requireBirthDate({required}: Schema, {value}: Values): Errors {
    if (required && !value) {
        return [ErrorMsg.required];
    }
    return [];
}

export function serializeBirthDate(values: Values, request: RegistrationRequest) {
    const date = values.value ? toUTCDate(values.value) : null;
    request.body.birth_date = {value: date};
}

export function renderBirthDateSummary(schema: Schema, values: Values, errors: Errors): SummaryItem[] {
    let display = "";
    if (values.value) {
        display = formatTrueDate(values.value);
    }
    return [{key: "value", label: "Дата рождения", value: display, hasErrors: errors.length > 0}];
}
