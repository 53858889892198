import {ActionIcon, Button, FileButton, Group, Input, rem, Stack, Text, Image} from "@mantine/core";
import {ReactNode, useCallback, useRef} from "react";
import {IconTrash} from "@tabler/icons-react";
import {useMatches} from "@mantine/core";
import { globalFileStorage, storeFile } from "../../core/file-storage";

export interface Props {
  label: string,
  description: string,
  withAsterisk?: boolean,
  exampleSrc?: string,
  fileId: string | null | undefined,
  onChange: (fid: string | null) => void,
  error?: ReactNode,
}

export default function BasePhotoField({label, description, withAsterisk, exampleSrc, fileId, onChange, error}: Props) {
  const file = fileId ? globalFileStorage.get(fileId) : undefined;
  const resetRef = useRef<() => void>(null);
  const onFileChange = useCallback((file: File | null) => {
    if (file) {
      const fileId = storeFile(file);
      onChange(fileId);
    } else {
      onChange(null);
    }
  }, [onChange]);
  const clearFile = () => {
    onChange(null);
    resetRef.current?.();
  };

  const w48_96 = useMatches({
    base: rem(48),
    xs: rem(96),
  });

  return (
    <Group wrap="nowrap" align="flex-start">
      {exampleSrc && <Image src={exampleSrc} w={w48_96}/>}
      <Stack gap={rem(8)}>
        <div>
          <Input.Label required={withAsterisk}>{label}</Input.Label>
          <Input.Description>{description}</Input.Description>
        </div>
        {file && (
          <Text>Выбран файл: {file.name}</Text>
        )}
        <FileButton onChange={onFileChange} resetRef={resetRef} accept="image/png,image/jpeg">
          {props => (file
             ? (
               <Group gap={rem(8)}>
                 <Button variant="outline" {...props}>Заменить</Button>
                 <ActionIcon variant="outline" color="gray" size="lg" onClick={() => clearFile()}>
                   <IconTrash style={{width: 16, height: 16}}/>
                 </ActionIcon>
               </Group>
             )
             : (
               <Button styles={{root: {alignSelf: "flex-start"}}} {...props}>Выбрать файл</Button>
             ))}
        </FileButton>
        {error && (
          <Input.Error>{error}</Input.Error>
        )}
      </Stack>
    </Group>
  );
}
