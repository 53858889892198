/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ValidationErrorEnum } from './ValidationErrorEnum';
import {
    ValidationErrorEnumFromJSON,
    ValidationErrorEnumFromJSONTyped,
    ValidationErrorEnumToJSON,
} from './ValidationErrorEnum';
import type { RegistrationValidateError } from './RegistrationValidateError';
import {
    RegistrationValidateErrorFromJSON,
    RegistrationValidateErrorFromJSONTyped,
    RegistrationValidateErrorToJSON,
} from './RegistrationValidateError';

/**
 * 
 * @export
 * @interface RegistrationValidateValidationError
 */
export interface RegistrationValidateValidationError {
    /**
     * 
     * @type {ValidationErrorEnum}
     * @memberof RegistrationValidateValidationError
     */
    type: ValidationErrorEnum;
    /**
     * 
     * @type {Array<RegistrationValidateError>}
     * @memberof RegistrationValidateValidationError
     */
    errors: Array<RegistrationValidateError>;
}

/**
 * Check if a given object implements the RegistrationValidateValidationError interface.
 */
export function instanceOfRegistrationValidateValidationError(value: object): value is RegistrationValidateValidationError {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('errors' in value) || value['errors'] === undefined) return false;
    return true;
}

export function RegistrationValidateValidationErrorFromJSON(json: any): RegistrationValidateValidationError {
    return RegistrationValidateValidationErrorFromJSONTyped(json, false);
}

export function RegistrationValidateValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationValidateValidationError {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ValidationErrorEnumFromJSON(json['type']),
        'errors': ((json['errors'] as Array<any>).map(RegistrationValidateErrorFromJSON)),
    };
}

export function RegistrationValidateValidationErrorToJSON(value?: RegistrationValidateValidationError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ValidationErrorEnumToJSON(value['type']),
        'errors': ((value['errors'] as Array<any>).map(RegistrationValidateErrorToJSON)),
    };
}

