import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { FormSliceState, References } from "../reducer";
import { BuildingTypesApi, CountriesApi, ResidentialUnitTypesApi, SettlementTypesApi, StreetTypesApi } from "../../../../client";
import { clientConfig } from "../../../../core/api/client";
import { setFailed, setLoaded, setLoading } from "../../../../core/api/loadable";

const fetchReferences = createAsyncThunk<References>(
  "form/fetchReferences",
  async (): Promise<References> => {
    const countriesPromise = new CountriesApi(clientConfig).countriesList();
    const settlementTypesPromise = new SettlementTypesApi(clientConfig).settlementTypesList();
    const streetTypesPromise = new StreetTypesApi(clientConfig).streetTypesList();
    const buildingTypesPromise = new BuildingTypesApi(clientConfig).buildingTypesList();
    const resUnitTypesPromise = new ResidentialUnitTypesApi(clientConfig).residentialUnitTypesList();

    const lists = await Promise.all([countriesPromise, settlementTypesPromise, streetTypesPromise, buildingTypesPromise, resUnitTypesPromise]);
    const countries = lists[0];
    const settlementTypes = lists[1];
    const streetTypes = lists[2];
    const buildingTypes = lists[3];
    const residentialUnitTypes = lists[4];

    return {countries, settlementTypes, streetTypes, buildingTypes, residentialUnitTypes};
  },
);
export default fetchReferences;

export function addFetchReferencesCases(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addCase(fetchReferences.pending, state => {
    setLoading(state.references);
  });
  builder.addCase(fetchReferences.fulfilled, (state, action) => {
    setLoaded(state.references, action.payload);
  });
  builder.addCase(fetchReferences.rejected, (state, action) => {
    /* eslint-disable-next-line no-console */
    console.error("fetchReferences rejected", action.error);
    setFailed(state.references, "Не удалось подготовить форму регистрации. Попробуйте обновить страницу.");
  });
}
