/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Тип помещения.
 * @export
 * @interface ResidentialUnitType
 */
export interface ResidentialUnitType {
    /**
     * 
     * @type {number}
     * @memberof ResidentialUnitType
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ResidentialUnitType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentialUnitType
     */
    abbr: string;
}

/**
 * Check if a given object implements the ResidentialUnitType interface.
 */
export function instanceOfResidentialUnitType(value: object): value is ResidentialUnitType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('abbr' in value) || value['abbr'] === undefined) return false;
    return true;
}

export function ResidentialUnitTypeFromJSON(json: any): ResidentialUnitType {
    return ResidentialUnitTypeFromJSONTyped(json, false);
}

export function ResidentialUnitTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentialUnitType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'abbr': json['abbr'],
    };
}

export function ResidentialUnitTypeToJSON(value?: Omit<ResidentialUnitType, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'abbr': value['abbr'],
    };
}

