/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegistrationSubmitError } from './RegistrationSubmitError';
import {
    RegistrationSubmitErrorFromJSON,
    RegistrationSubmitErrorFromJSONTyped,
    RegistrationSubmitErrorToJSON,
} from './RegistrationSubmitError';
import type { ValidationErrorEnum } from './ValidationErrorEnum';
import {
    ValidationErrorEnumFromJSON,
    ValidationErrorEnumFromJSONTyped,
    ValidationErrorEnumToJSON,
} from './ValidationErrorEnum';

/**
 * 
 * @export
 * @interface RegistrationSubmitValidationError
 */
export interface RegistrationSubmitValidationError {
    /**
     * 
     * @type {ValidationErrorEnum}
     * @memberof RegistrationSubmitValidationError
     */
    type: ValidationErrorEnum;
    /**
     * 
     * @type {Array<RegistrationSubmitError>}
     * @memberof RegistrationSubmitValidationError
     */
    errors: Array<RegistrationSubmitError>;
}

/**
 * Check if a given object implements the RegistrationSubmitValidationError interface.
 */
export function instanceOfRegistrationSubmitValidationError(value: object): value is RegistrationSubmitValidationError {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('errors' in value) || value['errors'] === undefined) return false;
    return true;
}

export function RegistrationSubmitValidationErrorFromJSON(json: any): RegistrationSubmitValidationError {
    return RegistrationSubmitValidationErrorFromJSONTyped(json, false);
}

export function RegistrationSubmitValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationSubmitValidationError {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ValidationErrorEnumFromJSON(json['type']),
        'errors': ((json['errors'] as Array<any>).map(RegistrationSubmitErrorFromJSON)),
    };
}

export function RegistrationSubmitValidationErrorToJSON(value?: RegistrationSubmitValidationError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ValidationErrorEnumToJSON(value['type']),
        'errors': ((value['errors'] as Array<any>).map(RegistrationSubmitErrorToJSON)),
    };
}

