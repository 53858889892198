import classes from "./FormStepCard.module.css";
import AnchorButton from "./AnchorButton.tsx";
import {Title, TitleProps, rem} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import FormStepper from "./FormStepper.tsx";
import { useAppDispatch, useAppSelector } from "../store/index.ts";
import { getFormContext } from "../core/form/render-context.tsx";
import { goToStep, prevStep } from "../store/features/form/reducer.ts";
import validateStep from "../store/features/form/thunks/validate-step.ts";
import ErrorAlert from "./ErrorAlert.tsx";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { FormStepKey } from "../core/form/steps.ts";
import ConfirmationStepForm from "./ConfirmationStepForm.tsx";
import FormStepForm from "./FormStepForm.tsx";
import submit from "../store/features/form/thunks/submit.ts";
import { BlockerFunction, useBeforeUnload, useBlocker, useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import SelectedProviderCard from "./SelectedProviderCard.tsx";
import { FieldType } from "../core/form/model.ts";
import { isFieldTypeSupported, isStepValid } from "../core/form/utils.ts";

export const cardBreakpoint = 50;

export default function FormStepCard() {
  const formState = useAppSelector(state => state.form.formState.value!);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const submitAndNavigate = useCallback(() => {
    dispatch(submit()).unwrap().then(errors => {
      if (!errors) {
        navigate(`/register/${formState.provider.name}/done/`);
      }
    });
  }, [dispatch, formState.provider.name, navigate]);

  const confirmUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = true;
  }, []);
  useBeforeUnload(confirmUnload);

  const shouldBlock: BlockerFunction = useCallback(({nextLocation}) => {
    if (nextLocation.pathname.endsWith("/done/")) {
      return false;
    }
    return true;
  }, []);
  const blocker = useBlocker(shouldBlock);
  useEffect(() => {
    if (blocker.state === "blocked") {
      modals.openConfirmModal({
        title: 'Покинуть страницу?',
        children: "Все заполненные поля будут потеряны.",
        labels: { confirm: 'Покинуть', cancel: 'Отмена' },
        onConfirm: () => blocker.proceed?.(),
        onCancel: () => blocker.reset?.(),
      });
    }
  }, [blocker, blocker.state])

  const formContext = getFormContext(formState.schema.schema);
  const stepIx = formContext.stepContexts.findIndex(ctx => ctx.step.key === formState.currentStep);
  const stepCtx = formContext.stepContexts[stepIx];
  const isConfirmationStep = stepCtx.step.key === FormStepKey.Confirm;
  const [mapOpen, {open: openMap, close: closeMap}] = useDisclosure(false);

  const dispatchGoToStep = useCallback((newIx: number) => {
    dispatch(goToStep({step: formContext.stepContexts[newIx].step.key}));
    closeMap();
  }, [dispatch, formContext.stepContexts, closeMap]);

  const isMobile = useMediaQuery(`(max-width: ${cardBreakpoint}rem)`);

  const classNames = [classes.root];
  if (isMobile) {
    classNames.push(classes["root--mobile"]);
  } else {
    classNames.push(classes["root--desktop"]);
  }
  if (mapOpen) classNames.push(classes["root--map-open"]);

  let allFieldsSupported = true;
  for (const fieldType of Object.keys(formState.schema.schema)) {
    if (!isFieldTypeSupported(fieldType as FieldType)) {
      allFieldsSupported = false;
      // eslint-disable-next-line no-console
      console.error(`Поле типа ${fieldType} не поддерживается.`);
    }
  }
  if (!allFieldsSupported) {
    return (
      <SelectedProviderCard provider={formState.provider} switchLink variant="content">
        <ErrorAlert>Требования этого оператора к регистрации сим-карт пока не поддерживаются. Попробуйте еще раз позже.</ErrorAlert>
      </SelectedProviderCard>
    );
  }

  const title = isConfirmationStep ? "Проверьте перед отправкой" : stepCtx.step.title;

  return (
    <SelectedProviderCard provider={formState.provider} switchLink variant="compact" classNames={{root: classNames.join(" ")}}>
      <div className={[classes["column"], classes["column--map"]].join(" ")}>
        {isMobile && <FormStepCard.Title mb={rem(16)}><AnchorButton onClick={closeMap}>Назад</AnchorButton></FormStepCard.Title>}
        <FormStepper
          active={stepIx}
          onStepClick={dispatchGoToStep}
          steps={formContext.stepContexts.map(ctx => ({key: ctx.step.key, title: ctx.step.title, hasErrors: !isStepValid(formState.errors, ctx.step.key)}))}
        />
      </div>
      <div className={[classes["column"], classes["column--content"]].join(" ")}>
        {isMobile ?
          (<FormStepCard.Title mb={rem(16)}><AnchorButton onClick={openMap}>Шаг {stepIx + 1}</AnchorButton>: {title}</FormStepCard.Title>) :
          (<FormStepCard.Title mb={rem(16)}>Шаг {stepIx + 1}: {title}</FormStepCard.Title>)}
        {isConfirmationStep ? (
          <ConfirmationStepForm
            stepKey={formState.currentStep}
            onPrev={() => dispatch(prevStep())}
            onSubmit={submitAndNavigate}
          />
        ) : (
          <FormStepForm
            stepKey={formState.currentStep}
            onPrev={() => dispatch(prevStep())}
            onNext={() => dispatch(validateStep({stepKey: formState.currentStep}))}
          />
        )}
      </div>
    </SelectedProviderCard>
  );
}

function FormStepCardTitle({children, ...rest}: PropsWithChildren<TitleProps>) {
  return (
    <Title order={2} classNames={{root: classes.title}} {...rest}>{children}</Title>
  );
}
FormStepCard.Title = FormStepCardTitle;
