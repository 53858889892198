/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Группа полей ФИО.
 * @export
 * @interface FullNameGroupRequest
 */
export interface FullNameGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof FullNameGroupRequest
     */
    last_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullNameGroupRequest
     */
    first_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullNameGroupRequest
     */
    patronymic: string | null;
}

/**
 * Check if a given object implements the FullNameGroupRequest interface.
 */
export function instanceOfFullNameGroupRequest(value: object): value is FullNameGroupRequest {
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('patronymic' in value) || value['patronymic'] === undefined) return false;
    return true;
}

export function FullNameGroupRequestFromJSON(json: any): FullNameGroupRequest {
    return FullNameGroupRequestFromJSONTyped(json, false);
}

export function FullNameGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullNameGroupRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'last_name': json['last_name'],
        'first_name': json['first_name'],
        'patronymic': json['patronymic'],
    };
}

export function FullNameGroupRequestToJSON(value?: FullNameGroupRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'last_name': value['last_name'],
        'first_name': value['first_name'],
        'patronymic': value['patronymic'],
    };
}

