import {PropsWithChildren} from "react";
import Header from "./Header.tsx";
import {CardProps, Container, ElementProps, rem} from "@mantine/core";
import {Card} from "@mantine/core";
import classes from "./AppLayout.module.css";
import Footer from "./Footer.tsx";

export default function AppLayout(props: PropsWithChildren) {
  return (
    <Container size="62rem">
      <Header/>
      {props.children}
      <Footer mt={rem(20)}/>
    </Container>
  );
}

export interface AppLayoutCardProps extends CardProps, ElementProps<"div", keyof CardProps> {
  variant: "content" | "compact",
  classNames?: {root?: string},
}

AppLayout.Card = function ({variant, classNames, children, ...rest}: PropsWithChildren<AppLayoutCardProps>) {
  const renderClassNames = [classes.card, classes[variant]];
  if (classNames && classNames.root) {
    renderClassNames.push(classNames.root);
  }
  return (
    <Card withBorder classNames={{root: renderClassNames.join(" ")}} {...rest}>
      {children}
    </Card>
  );
}
