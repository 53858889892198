export function parseIntOr<D>(s: string | null | undefined, def: D): number | D {
  if (s === null || s === undefined) {
    return def;
  }

  const n = parseInt(s);
  if (Number.isNaN(n)) {
    return def;
  } else {
    return n;
  }
}
