/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Поле абонентского номера сим-карты.
 * @export
 * @interface SimNumberRequest
 */
export interface SimNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof SimNumberRequest
     */
    value: string | null;
}

/**
 * Check if a given object implements the SimNumberRequest interface.
 */
export function instanceOfSimNumberRequest(value: object): value is SimNumberRequest {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function SimNumberRequestFromJSON(json: any): SimNumberRequest {
    return SimNumberRequestFromJSONTyped(json, false);
}

export function SimNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimNumberRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function SimNumberRequestToJSON(value?: SimNumberRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

