import { Stack, rem, Text, StackProps, ElementProps } from "@mantine/core";
import { getFormContext } from "../core/form/render-context";
import { useAppDispatch, useAppSelector } from "../store";
import { FormStepKey } from "../core/form/steps";
import classes from "./FormSummary.module.css";
import AnchorButton from "./AnchorButton";
import { goToStep } from "../store/features/form/reducer";
import { useReferences } from "../store/features/form/hooks";
import classNames from "classnames";
import { renderFieldSummary } from "../core/form/utils";

interface Props extends StackProps, ElementProps<"div", keyof StackProps> {}

export default function FormSummary(props: Props) {
  const formState = useAppSelector(state => state.form.formState.value!);
  const formContext = getFormContext(formState.schema.schema);
  const references = useReferences();
  const dispatch = useAppDispatch();

  return (
    <Stack gap={rem(16)} {...props}>
      {formContext.stepContexts.filter(stepCtx => stepCtx.step.key !== FormStepKey.Confirm).map(stepCtx => (
        <Stack key={stepCtx.step.key} gap={rem(8)}>
          <div className={classes.titleGroup}>
            <Text className={classes.title}>{stepCtx.step.title}</Text>
            <AnchorButton className={classes.editLink} onClick={() => dispatch(goToStep({step: stepCtx.step.key}))}>Исправить</AnchorButton>
          </div>
          {stepCtx.fieldContexts.flatMap(fieldCtx => (
            renderFieldSummary(fieldCtx.type, formState.schema.schema, formState.values, formState.errors, references).map(item => (
              <FormSummary.Item key={`${fieldCtx.type}-${item.key}`} label={item.label} value={item.value} hasErrors={item.hasErrors}/>
            ))
          ))}
        </Stack>
      ))}
    </Stack>
  );
}

interface ItemProps {
  label: string,
  value?: string | null,
  hasErrors?: boolean,
}

function FormSummaryItem({label, value, hasErrors}: ItemProps) {
  return (
    <div className={classNames(classes.item, hasErrors && classes["item--invalid"])}>
      <Text className={classes.label}>{label}</Text>
      {value ? <Text>{value}</Text> : <Text className={classes.empty}>нет</Text>}
    </div>
  );
}
FormSummary.Item = FormSummaryItem;
