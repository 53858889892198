import {PropsWithChildren} from "react";
import {Box, BoxProps, ElementProps} from "@mantine/core";
import "../content/blocks/html-content.css";

interface Props extends BoxProps, ElementProps<"div", keyof BoxProps> {
  dangerouslySetInnerHTML?: {__html: string},
}

export default function ContentHTML({dangerouslySetInnerHTML, children, ...rest}: PropsWithChildren<Props>) {
  return (
    <Box className="html-content html-content--app" dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...rest}>{children}</Box>
  );
}
