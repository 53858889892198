import { Anchor, Box, BoxProps, ElementProps, Text } from "@mantine/core";
import { getSiteSettings } from "../core/site-settings";
import "../content/blocks/footer.css";

export interface Props extends BoxProps, ElementProps<"div", keyof BoxProps> {}

export default function Footer(props: Props) {
  const siteSettings = getSiteSettings();
  const year = (new Date()).getFullYear();

  return (
    <Box className="footer" {...props}>
      {siteSettings.footer_links.length > 0 && (
        <div className="footer__link-group">
          {siteSettings.footer_links.map((link, ix) => (
            <Anchor key={ix} href={link.url} target="_blank">{link.label}</Anchor>
          ))}
        </div>
      )}
      {(siteSettings.contact_links.length > 0 || siteSettings.copyright_holder) && (
        <div className="footer__link-group">
          {siteSettings.contact_links.map((link, ix) => (
            <Anchor key={ix} href={link.url} target="_blank">{link.label}</Anchor>
          ))}
          {siteSettings.copyright_holder && (
            <Text className="footer__copyright">© {year} {siteSettings.copyright_holder}</Text>
          )}
        </div>
      )}
      {siteSettings.footer_disclaimer && (
        <Text className="footer__disclaimer">{siteSettings.footer_disclaimer}</Text>
      )}
    </Box>
  );
}
