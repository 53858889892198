import { FieldState } from "../../../client";
import { References } from "../../../store/features/form/reducer";
import { formatTrueDate, toUTCDate } from "../../../utils/dates";
import { onlyDigits } from "../../../utils/strings";
import { RegistrationRequest } from "../../api/types";
import { ErrorMsg, FieldErrors } from "../errors";
import { FieldSchema, FieldType, SummaryItem } from "../model";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.PassportGroup>;
type Values = FieldValues<FieldType.PassportGroup>;
type Errors = FieldErrors<FieldType.PassportGroup>;

export function emptyPassportGroupValues(): Values {
    return {
        foreign_citizenship: false,
        citizenship_country: null,
        series: "",
        number: "",
        issued_by: "",
        issue_date: null,
        expiration_date: null,
        subdivision_code: "",
    };
}

export function emptyPassportGroupErrors(): Errors {
    return {
        non_field_errors: [],
        foreign_citizenship: [],
        citizenship_country: [],
        series: [],
        number: [],
        issued_by: [],
        issue_date: [],
        expiration_date: [],
        subdivision_code: [],
    };
}

export function requirePassportGroup(schema: Schema, values: Values): Errors {
    if (values.foreign_citizenship) {
        return requireForeignPassport(schema, values);
    } else {
        return requireNationalPassport(schema, values);
    }
}

function requireNationalPassport(schema: Schema, values: Values): Errors {
    const errors = emptyPassportGroupErrors();

    if (schema.series === FieldState.Required && !values.series) {
        errors.series = [ErrorMsg.required];
    }
    if (schema.number === FieldState.Required && !values.number) {
        errors.number = [ErrorMsg.required];
    }
    if (schema.issued_by === FieldState.Required && !values.issued_by) {
        errors.issued_by = [ErrorMsg.required];
    }
    if (schema.issue_date === FieldState.Required && !values.issue_date) {
        errors.issue_date = [ErrorMsg.required];
    }
    if (schema.subdivision_code === FieldState.Required && !values.subdivision_code) {
        errors.subdivision_code = [ErrorMsg.required];
    }

    return errors;
}

function requireForeignPassport(schema: Schema, values: Values): Errors {
    const errors = emptyPassportGroupErrors();

    if (schema.foreign_citizenship_country === FieldState.Required && !values.citizenship_country) {
        errors.citizenship_country = [ErrorMsg.required];
    }
    if (schema.foreign_number === FieldState.Required && !values.number) {
        errors.number = [ErrorMsg.required];
    }
    if (schema.foreign_issued_by === FieldState.Required && !values.issued_by) {
        errors.issued_by = [ErrorMsg.required];
    }
    if (schema.foreign_issue_date === FieldState.Required && !values.issue_date) {
        errors.issue_date = [ErrorMsg.required];
    }
    if (schema.foreign_expiration_date === FieldState.Required && !values.expiration_date) {
        errors.expiration_date = [ErrorMsg.required];
    }

    return errors;
}

export function validatePassportGroup(schema: Schema, values: Values): Errors {
    const errors = emptyPassportGroupErrors();

    if (!values.foreign_citizenship) {
        if (values.series && !/^\d{4}$/.test(values.series)) {
            errors.series = ["Серия должна состоять из 4 цифр."];
        }

        if (values.number && !/^\d{6}$/.test(values.number)) {
            errors.number = ["Номер должен состоять из 6 цифр."];
        }

        if (values.subdivision_code && onlyDigits(values.subdivision_code).length !== 6) {
            errors.subdivision_code = ["Код подразделения должен состоять из 6 цифр."]
        }
    }

    return errors;
}

export function serializePassportGroup(values: Values, request: RegistrationRequest) {
    const issue_date = values.issue_date ? toUTCDate(values.issue_date) : null;
    const expiration_date = values.expiration_date ? toUTCDate(values.expiration_date) : null;
    request.body.passport_group = {...values, issue_date, expiration_date};
}

export function renderPassportGroupSummary(schema: Schema, values: Values, errors: Errors, references: References): SummaryItem[] {
    const hasNonFieldErrors = errors.non_field_errors.length > 0;

    const items: SummaryItem[] = [];

    if (schema.foreign_enabled) {
        items.push({
            key: "citizenship",
            label: "Гражданство",
            value: values.foreign_citizenship ? "Иностранный гражданин" : "Гражданин России",
            hasErrors: errors.foreign_citizenship.length > 0 || hasNonFieldErrors,
        });
    }

    if (values.foreign_citizenship) {
        items.push(...renderForeignPassportGroupSummary(schema, values, errors, references));
    } else {
        items.push(...renderNationalPassportGroupSummary(schema, values, errors));
    }

    return items;
}

function renderNationalPassportGroupSummary(schema: Schema, values: Values, errors: Errors): SummaryItem[] {
    const hasNonFieldErrors = errors.non_field_errors.length > 0;

    const items: SummaryItem[] = [];

    if (schema.series !== FieldState.Hidden) {
        items.push({
            key: "series",
            label: "Серия",
            value: values.series,
            hasErrors: errors.series.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.number !== FieldState.Hidden) {
        items.push({
            key: "number",
            label: "Номер",
            value: values.number,
            hasErrors: errors.number.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.issued_by !== FieldState.Hidden || schema.issue_date !== FieldState.Hidden) {
        let issued = "";
        if (values.issue_date && values.issued_by) {
            issued = `${formatTrueDate(values.issue_date)} ${values.issued_by}`;
        } else if (values.issue_date) {
            issued = formatTrueDate(values.issue_date);
        } else if (values.issued_by) {
            issued = values.issued_by;
        }

        items.push({
            key: "issued",
            label: "Выдан",
            value: issued,
            hasErrors: errors.issue_date.length > 0 || errors.issued_by.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.subdivision_code !== FieldState.Hidden) {
        items.push({
            key: "subdivision_code",
            label: "Код подразделения",
            value: values.subdivision_code || null,
            hasErrors: errors.subdivision_code.length > 0 || hasNonFieldErrors,
        });
    }

    return items;
}

function renderForeignPassportGroupSummary(schema: Schema, values: Values, errors: Errors, references: References): SummaryItem[] {
    const hasNonFieldErrors = errors.non_field_errors.length > 0;

    const items: SummaryItem[] = [];

    if (schema.foreign_citizenship_country !== FieldState.Hidden) {
        const country = references.countries.find(c => c.iso_code === values.citizenship_country);
        items.push({
            key: "citizenship_country",
            label: "Страна",
            value: country?.display_name || null,
            hasErrors: errors.citizenship_country.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.foreign_number !== FieldState.Hidden) {
        items.push({
            key: "number",
            label: "Номер",
            value: values.number,
            hasErrors: errors.number.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.foreign_issued_by !== FieldState.Hidden || schema.foreign_issue_date !== FieldState.Hidden) {
        let issued = "";
        if (values.issue_date && values.issued_by) {
            issued = `${formatTrueDate(values.issue_date)} ${values.issued_by}`;
        } else if (values.issue_date) {
            issued = formatTrueDate(values.issue_date);
        } else if (values.issued_by) {
            issued = values.issued_by;
        }

        items.push({
            key: "issued",
            label: "Выдан",
            value: issued,
            hasErrors: errors.issue_date.length > 0 || errors.issued_by.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.foreign_expiration_date !== FieldState.Hidden) {
        items.push({
            key: "expiration_date",
            label: "Действителен до",
            value: values.expiration_date ? formatTrueDate(values.expiration_date) : null,
            hasErrors: errors.expiration_date.length > 0 || hasNonFieldErrors,
        });
    }

    return items;
}
