import { CheckIcon, Combobox, ComboboxItem, ElementProps, Input, InputBase, InputWrapperProps, ScrollArea, useCombobox } from "@mantine/core";
import { useState } from "react";
import classes from "./SearchableSelect.module.css";

interface Props extends Omit<InputWrapperProps, 'onChange'>, ElementProps<"div", keyof InputWrapperProps> {
  value?: string | null,
  onChange?: (value: string | null) => void,
  data?: ComboboxItem[],
  placeholder?: string,
  disabled?: boolean,
  allowDeselect?: boolean,
}

export default function SearchableSelect({value, onChange, data, placeholder, disabled, allowDeselect, error, ...rest}: Props) {
  const [search, setSearch] = useState('');
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
  });

  const filteredOptions = (data || [])
    .filter(item => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    .map(item => (
      <Combobox.Option value={item.value} key={item.value} className={classes.option}>
        {item.value === value && <CheckIcon className={classes["option-check-icon"]}/>}
        {item.label}
      </Combobox.Option>
    ));
  const valueOption = data?.find(item => item.value === value);

  return (
    <Input.Wrapper {...rest}>
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          if (val === value) {
            if (allowDeselect) {
              onChange?.(null);
            }
          } else {
            onChange?.(val);
          }
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
            disabled={disabled}
            error={error}
          >
            {valueOption?.label || <Input.Placeholder>{placeholder}</Input.Placeholder>}
          </InputBase>
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Search
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Поиск"
          />
          <Combobox.Options>
            <ScrollArea.Autosize type="scroll" mah={200}>
              {filteredOptions.length > 0 ? filteredOptions : <Combobox.Empty>Пусто</Combobox.Empty>}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Input.Wrapper>
  );
}
