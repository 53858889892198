import {DefaultMantineColor, Stepper} from "@mantine/core";
import classes from "./FormStepper.module.css";
import {IconAlertCircle, IconCheck} from "@tabler/icons-react";
import { FormStepKey } from "../core/form/steps";
import { ReactNode } from "react";

interface Props {
  active: number,
  onStepClick: (newActive: number) => void,
  steps: {key: FormStepKey, title: string, hasErrors: boolean}[],
}

export default function FormStepper({active, onStepClick, steps}: Props) {
  return (
    <Stepper
      active={active}
      onStepClick={onStepClick}
      allowNextStepsSelect={false}
      orientation="vertical"
      classNames={{step: classes.step, stepBody: classes.stepBody, stepLabel: classes.stepLabel}}
    >
      {steps.map((step, index) => {
        const isFilled = index < active;

        let color: DefaultMantineColor | undefined = undefined;
        let description: string | undefined = undefined;
        let icon: ReactNode | undefined = undefined;
        let completedIcon: ReactNode | undefined = undefined;
        if (isFilled) {
          if (step.hasErrors) {
            description = "Исправьте ошибки";
            color = "red";
            completedIcon = <IconAlertCircle/>;
          } else {
            description = "Заполнено";
          }
        } else {
          if (step.key === FormStepKey.Confirm) {
            icon = <IconCheck/>;
          }
        }

        return (
          <Stepper.Step
            key={step.key}
            label={step.title}
            color={color}
            description={description}
            icon={icon}
            completedIcon={completedIcon}
          />
        );
      })}
    </Stepper>
  );
}
