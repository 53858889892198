import { Anchor, ElementProps, Group, Image, Stack, Title, rem } from "@mantine/core";
import { Provider } from "../client";
import { Link } from "react-router-dom";
import classes from "./SelectedProviderCard.module.css";
import AppLayout, { AppLayoutCardProps } from "./AppLayout";
import { getSiteSettings } from "../core/site-settings";

interface Props extends AppLayoutCardProps, ElementProps<"div", keyof AppLayoutCardProps> {
  provider: Provider,
  switchLink?: boolean,
}

export default function SelectedProviderCard({provider, switchLink, ...rest}: Props) {
  const siteSettings = getSiteSettings();

  return (
    <Stack>
      {siteSettings.site_providers.length > 1 && (
        <Group gap={rem(8)} align="baseline">
          {provider.icon && (
            <Image src={provider.icon} w={rem(24)} h={rem(24)} fit="contain" style={{alignSelf: "center"}}/>
          )}
          <Title classNames={{root: classes["title"]}}>{provider.display_name}</Title>
          {switchLink && (
            <Anchor component={Link} classNames={{root: classes["switch-link"]}} to="/">Другой оператор</Anchor>
          )}
        </Group>
      )}
      <AppLayout.Card {...rest}/>
    </Stack>
  );
}
