import { Provider } from "../client";
import AppLayout from "../components/AppLayout";
import ContentTitle from "../components/ContentTitle";
import {Button, Center, Image, Stack, rem} from "@mantine/core";
import checkmarkSrc from "../assets/primary-checkmark.svg";
import ContentHTML from "../components/ContentHTML";
import { Link, useLoaderData } from "react-router-dom";
import SelectedProviderCard from "../components/SelectedProviderCard";
import { getSiteSettings } from "../core/site-settings";

export interface RegisterProviderLoaderData {
  provider: Provider,
}

export default function RegisterProviderDonePage() {
  const loaderData = useLoaderData() as RegisterProviderLoaderData;
  const siteSettings = getSiteSettings();

  return (
    <AppLayout>
      <SelectedProviderCard provider={loaderData.provider} variant="content" pt={rem(48)}>
        <Center mb={24}>
          <Image src={checkmarkSrc} w={40} h="auto"/>
        </Center>
        <ContentTitle styles={{root: {textAlign: "center"}}} mb={24}>Ваша заявка принята!</ContentTitle>
        <Stack gap={rem(16)} mb={32}>
          {siteSettings.success_content.map((block, ix) => {
            if (block.block_type === "rich_text") {
              return (
                <ContentHTML key={ix} dangerouslySetInnerHTML={{__html: block.value}}/>
              );
            } else {
              // eslint-disable-next-line no-console
              console.warn(`Content block of type ${block.block_type} isn't supported.`);
            }
          })}
        </Stack>
        <Button variant="outline" component={Link} to="/" styles={{root: {alignSelf: "flex-start"}}}>Вернуться в начало</Button>
      </SelectedProviderCard>
    </AppLayout>
  );
}
