export enum FormStepKey {
  SimParams = 'sim-params',
  BioData = 'biodata',
  Address = 'address',
  Passport = 'passport',
  Confirm = 'confirm',
}

export interface FormStep {
  key: FormStepKey,
  title: string,
  order: number,
}

export const FormStepMap: Record<FormStepKey, FormStep> = {
  [FormStepKey.SimParams]: {
    key: FormStepKey.SimParams,
    title: "Параметры сим-карты",
    order: 1,
  },
  [FormStepKey.BioData]: {
    key: FormStepKey.BioData,
    title: "Личные данные",
    order: 2,
  },
  [FormStepKey.Address]: {
    key: FormStepKey.Address,
    title: "Адрес регистрации",
    order: 3,
  },
  [FormStepKey.Passport]: {
    key: FormStepKey.Passport,
    title: "Паспорт",
    order: 4,
  },
  [FormStepKey.Confirm]: {
    key: FormStepKey.Confirm,
    title: "Готово",
    order: 5,
  },
}

export interface StepState {
  isValidating: boolean,
  // Проходил валидацию успешно хотя бы один раз
  wasValidated: boolean,
  // Содержал ошибки при последней валидации
  hadErrors: boolean,
  // Не удалось выполнить валидацию (ошибка сети или сервера)
  validationFailureMessage: string | undefined,
}
export function newStepState(): StepState {
  return {
    isValidating: false,
    wasValidated: false,
    hadErrors: false,
    validationFailureMessage: undefined,
  };
}

export type StepStateMap = Record<FormStepKey, StepState>;
export function newStepStateMap(): StepStateMap {
  return {
    [FormStepKey.SimParams]: newStepState(),
    [FormStepKey.BioData]: newStepState(),
    [FormStepKey.Address]: newStepState(),
    [FormStepKey.Passport]: newStepState(),
    [FormStepKey.Confirm]: newStepState(),
  };
}
