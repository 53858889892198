/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `validation_error` - Validation Error
 * @export
 */
export const ValidationErrorEnum = {
    ValidationError: 'validation_error'
} as const;
export type ValidationErrorEnum = typeof ValidationErrorEnum[keyof typeof ValidationErrorEnum];


export function instanceOfValidationErrorEnum(value: any): boolean {
    for (const key in ValidationErrorEnum) {
        if (Object.prototype.hasOwnProperty.call(ValidationErrorEnum, key)) {
            if ((ValidationErrorEnum as Record<string, ValidationErrorEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ValidationErrorEnumFromJSON(json: any): ValidationErrorEnum {
    return ValidationErrorEnumFromJSONTyped(json, false);
}

export function ValidationErrorEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorEnum {
    return json as ValidationErrorEnum;
}

export function ValidationErrorEnumToJSON(value?: ValidationErrorEnum | null): any {
    return value as any;
}

