import { makeHTTPError } from "../../core/api/errors";
import { Middleware, ResponseContext } from "../runtime";

export default class ErrorMiddleware implements Middleware {
  async post(context: ResponseContext): Promise<Response | void> {
    if (context.response.status >= 400 && context.response.status <= 599) {
      throw await makeHTTPError(context);
    }
  }
}
