import {Box, Image} from "@mantine/core";
import classes from "./Header.module.css";
import defaultLogo from "../assets/default-logo.svg";
import { getSiteSettings } from "../core/site-settings";

export default function Header() {
  const siteSettings = getSiteSettings();

  return (
    <Box className={classes.root}>
      <Image src={siteSettings.logo || defaultLogo} h={24} w="auto"/>
    </Box>
  );
}
