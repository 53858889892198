import {Button, Center, rem, useMantineTheme} from '@mantine/core';
import ContentTitle from "../components/ContentTitle.tsx";
import AppLayout from "../components/AppLayout.tsx";
import { IconBug } from '@tabler/icons-react';
import { getSiteSettings } from '../core/site-settings.ts';
import ContentHTML from '../components/ContentHTML.tsx';


export default function ErrorPage() {
  const theme = useMantineTheme();
  const siteSettings = getSiteSettings();

  return (
    <AppLayout>
      <AppLayout.Card variant="content" pt={rem(48)}>
        <Center mb={24}>
          <IconBug size={48} strokeWidth={1.25} color={theme.colors.gray[6]}/>
        </Center>
        <ContentTitle styles={{root: {textAlign: "center"}}} mb={24}>Непредвиденная ошибка</ContentTitle>
        <ContentHTML mb={rem(24)}>
          <p>Попробуйте обновить страницу.</p>
          <p>Контакты поддержки:</p>
          <ul>
            {siteSettings.contact_links.map((link, ix) => (
              <li key={ix}><a href={link.url}>{link.label}</a></li>
            ))}
          </ul>
        </ContentHTML>
        <Button variant="outline" onClick={() => location.reload()} styles={{root: {alignSelf: "flex-start"}}}>Обновить страницу</Button>
      </AppLayout.Card>
    </AppLayout>
  );
}
