import dayjs from "dayjs";

/**
 * Стандартный тип Date может сдвигать дату при сериализации-десериализации из-за смены часового пояса.
 */
export interface TrueDate {
  y: number,
  m: number, // 1..12
  d: number,
}

/**
 * Возвращает полночь в выбранной дате в локальном часовом поясе.
 * {2020, 1, 2} => "2020-01-02 00:00:00+0300"
 */
export function toLocalDate(td: TrueDate): Date {
  return dayjs().
    year(td.y).
    month(td.m - 1).
    date(td.d).
    startOf('day').
    toDate();
}

export function fromLocalDate(d: Date): TrueDate {
  if (d.getHours() !== 0 || d.getMinutes() !== 0) {
    /* eslint-disable-next-line no-console */
    console.warn("fromLocalDate получил время, отличное от полуночи. Дата передана в неправильном часовом поясе?");
  }
  return {y: d.getFullYear(), m: d.getMonth() + 1, d: d.getDate()};
}

/**
 * Возвращает полночь в выбранной дате в UTC.
 * {2020, 1, 2} => "2020-01-02 03:00:00+0300"
 */
export function toUTCDate(td: TrueDate): Date {
  return dayjs().utc().
    year(td.y).
    month(td.m - 1).
    date(td.d).
    startOf('day').
    toDate();
}

export function formatTrueDate(td: TrueDate): string {
  return dayjs(toLocalDate(td)).format("DD.MM.YYYY");
}

export function parseTrueDate(s: string): TrueDate | undefined {
  const djs = dayjs(s, "DD.MM.YYYY");
  if (!djs.isValid()) {
    return undefined;
  }

  return {y: djs.year(), m: djs.month() + 1, d: djs.date()};
}
