import { FormSchema } from "../../client";
import { FormStep, FormStepKey, FormStepMap } from "./steps";
import { enumKeys } from "../../utils/data-structures";
import { FieldType, FormModel } from "./model";
import { getStepFieldTypes } from "./utils";

export interface FieldContext {
  type: FieldType,
  render: () => React.ReactNode,
}

function getFieldContext<K extends FieldType>(schema: FormSchema, type: K): FieldContext | null {
  const fieldSchema = schema[type];
  if (!fieldSchema) {
    return null;
  }

  const model = FormModel[type];
  return {
    type,
    render: () => <model.renderComponent key={type} fieldSchema={fieldSchema}/>,
  }
}

export interface StepContext {
  step: FormStep,
  fieldContexts: FieldContext[],
}

export interface FormContext {
  stepContexts: StepContext[],
}

export function getFormContext(schema: FormSchema): FormContext {
  const stepContexts: StepContext[] = [];

  for (const stepKey of enumKeys(FormStepKey).map(k => FormStepKey[k])) {
    const step = FormStepMap[stepKey];
    const isConfirmationStep = stepKey === FormStepKey.Confirm;

    const fieldContexts = getStepFieldTypes(stepKey).
      map(fieldType => getFieldContext(schema, fieldType)).
      filter(r => !!r);

    if (fieldContexts.length === 0 && !isConfirmationStep) continue;

    stepContexts.push({step, fieldContexts});
  }

  return {stepContexts};
}

export function getStepContextBefore(formContext: FormContext, stepKey: FormStepKey): StepContext | undefined {
  const stepIndex = formContext.stepContexts.findIndex(ctx => ctx.step.key === stepKey);
  if (stepIndex <= 0) {
    return;
  }

  return formContext.stepContexts[stepIndex - 1];
}

export function getStepContextAfter(formContext: FormContext, stepKey: FormStepKey): StepContext | undefined {
  const stepIndex = formContext.stepContexts.findIndex(ctx => ctx.step.key === stepKey);
  if (stepIndex < 0 || stepIndex === formContext.stepContexts.length - 1) {
    return undefined;
  }
  return formContext.stepContexts[stepIndex + 1];
}
