// Используем, чтобы ссылаться на файлы из store.
export const globalFileStorage: Map<string, File> = new Map();

export function newFileId(): string {
  const id = Math.random().toString().slice(2);
  return `file:${id}`;
}

export function storeFile(file: File): string {
  const id = newFileId();
  globalFileStorage.set(id, file);
  return id;
}
