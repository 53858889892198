/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FormDataRequest,
  RegistrationSchema,
  RegistrationSchemaRetrieveErrorResponse400,
  RegistrationSubmitErrorResponse400,
  RegistrationValidateErrorResponse400,
} from '../models/index';
import {
    FormDataRequestFromJSON,
    FormDataRequestToJSON,
    RegistrationSchemaFromJSON,
    RegistrationSchemaToJSON,
    RegistrationSchemaRetrieveErrorResponse400FromJSON,
    RegistrationSchemaRetrieveErrorResponse400ToJSON,
    RegistrationSubmitErrorResponse400FromJSON,
    RegistrationSubmitErrorResponse400ToJSON,
    RegistrationValidateErrorResponse400FromJSON,
    RegistrationValidateErrorResponse400ToJSON,
} from '../models/index';

export interface RegistrationSchemaRetrieveRequest {
    providerName: string;
}

export interface RegistrationSubmitRequest {
    providerName: string;
    body: FormDataRequest;
    passportBiodataPhoto?: Blob;
    passportAddressPhoto?: Blob;
}

export interface RegistrationValidateRequest {
    providerName: string;
    body: FormDataRequest;
    passportBiodataPhoto?: Blob;
    passportAddressPhoto?: Blob;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     * Схема (поля) формы регистрации.
     */
    async registrationSchemaRetrieveRaw(requestParameters: RegistrationSchemaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegistrationSchema>> {
        if (requestParameters['providerName'] == null) {
            throw new runtime.RequiredError(
                'providerName',
                'Required parameter "providerName" was null or undefined when calling registrationSchemaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/registration/{provider_name}/schema/`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters['providerName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationSchemaFromJSON(jsonValue));
    }

    /**
     * Схема (поля) формы регистрации.
     */
    async registrationSchemaRetrieve(requestParameters: RegistrationSchemaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegistrationSchema> {
        const response = await this.registrationSchemaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отправка заявки на регистрацию.
     */
    async registrationSubmitRaw(requestParameters: RegistrationSubmitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['providerName'] == null) {
            throw new runtime.RequiredError(
                'providerName',
                'Required parameter "providerName" was null or undefined when calling registrationSubmit().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling registrationSubmit().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['body'] != null) {
            formParams.append('body', new Blob([JSON.stringify(FormDataRequestToJSON(requestParameters['body']))], { type: "application/json", }));
                    }

        if (requestParameters['passportBiodataPhoto'] != null) {
            formParams.append('passport_biodata_photo', requestParameters['passportBiodataPhoto'] as any);
        }

        if (requestParameters['passportAddressPhoto'] != null) {
            formParams.append('passport_address_photo', requestParameters['passportAddressPhoto'] as any);
        }

        const response = await this.request({
            path: `/api/registration/{provider_name}/submit/`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters['providerName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отправка заявки на регистрацию.
     */
    async registrationSubmit(requestParameters: RegistrationSubmitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registrationSubmitRaw(requestParameters, initOverrides);
    }

    /**
     * Валидация формы регистрации.
     */
    async registrationValidateRaw(requestParameters: RegistrationValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['providerName'] == null) {
            throw new runtime.RequiredError(
                'providerName',
                'Required parameter "providerName" was null or undefined when calling registrationValidate().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling registrationValidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['body'] != null) {
            formParams.append('body', new Blob([JSON.stringify(FormDataRequestToJSON(requestParameters['body']))], { type: "application/json", }));
                    }

        if (requestParameters['passportBiodataPhoto'] != null) {
            formParams.append('passport_biodata_photo', requestParameters['passportBiodataPhoto'] as any);
        }

        if (requestParameters['passportAddressPhoto'] != null) {
            formParams.append('passport_address_photo', requestParameters['passportAddressPhoto'] as any);
        }

        const response = await this.request({
            path: `/api/registration/{provider_name}/validate/`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters['providerName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Валидация формы регистрации.
     */
    async registrationValidate(requestParameters: RegistrationValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registrationValidateRaw(requestParameters, initOverrides);
    }

}
