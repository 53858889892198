/*
enum ExampleEnum {
    KeyA = 'value-a',
    KeyB = 'value-b',
}

enumKeys(ExampleEnum)
// => ["KeyA", "KeyB"] типа (keyof ExampleEnum)[]

enumKeys(ExampleEnum).map(k => ExampleEnum[k])
// => ['value-a', 'value-b'] типа ('value-a' | 'value-b')[]
**/
export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => !Number.isNaN(k)) as K[]
}

export function keepKeys<T extends object, K extends keyof T>(
  sourceObj: T,
  keysToKeep: K[]
): Pick<T, K> {
  return Object.entries(sourceObj).reduce((acc, [key, value]) => {
    if (keysToKeep.includes(key as K)) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {} as Pick<T, K>);
}

export function excludeKeys<T extends object, K extends keyof T>(
  sourceObj: T,
  keysToExclude: K[]
): Omit<T, K> {
  return Object.entries(sourceObj).reduce((acc, [key, value]) => {
    if (!keysToExclude.includes(key as K)) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {} as Omit<T, K>);
}
