import {useAppDispatch, useAppSelector} from "../..";
import { References, initialReferences, updateErrors, updateValues } from "./reducer";
import { FormValues } from "../../../core/form/values";
import { useCallback } from "react";
import { FormErrors } from "../../../core/form/errors";
import { FormStepKey, StepState, newStepState } from "../../../core/form/steps";
import { FormModel } from "../../../core/form/model";
import { emptyFormErrors } from "../../../core/form/utils";

export const useReferences = (): References => {
  const references = useAppSelector(state => state.form.references.value);
  if (!references) {
    // eslint-disable-next-line no-console
    console.warn("References are not loaded");
    return initialReferences;
  }
  return references;
};

export function useFormValues<K extends keyof FormValues>(key: K): [FormValues[K], (v: FormValues[K]) => void] {
  const value = useAppSelector(state => {
    const formState = state.form.formState.value;
    if (!formState) {
      // eslint-disable-next-line no-console
      console.warn("FormState is requested by useFormValues but is not loaded");
      return FormModel[key].emptyValues();
    }
    return formState.values[key]
  });
  const dispatch = useAppDispatch();
  const setValue = useCallback((newValue: FormValues[K]) => {
    dispatch(updateValues({[key]: newValue}));
  }, [key, dispatch])
  return [value, setValue];
}

export function useFormErrors<K extends keyof FormErrors>(key: K): [FormErrors[K], (e: FormErrors[K]) => void] {
  const errors = useAppSelector(state => {
    const formState = state.form.formState.value;
    if (!formState) {
      // eslint-disable-next-line no-console
      console.warn("FormState is requested by useFormErrors but is not loaded");
      return emptyFormErrors()[key];
    }
    return formState.errors[key]
  });
  const dispatch = useAppDispatch();
  const setErrors = useCallback((newErrors: FormErrors[K]) => {
    dispatch(updateErrors({[key]: newErrors}));
  }, [key, dispatch])
  return [errors, setErrors];
}

export function useStepState(key: FormStepKey): StepState {
  return useAppSelector(state => {
    const formState = state.form.formState.value;
    if (!formState) {
      // eslint-disable-next-line no-console
      console.warn("FormState is requested by useStepState but is not loaded");
      return newStepState();
    }
    return formState.stepStates[key]
  });
}
