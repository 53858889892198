import { FieldState } from "../../../client";
import { RegistrationRequest } from "../../api/types";
import { ErrorMsg, FieldErrors } from "../errors";
import { FieldSchema, FieldType, FormModel, SummaryItem } from "../model";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.FullNameGroup>;
type Values = FieldValues<FieldType.FullNameGroup>;
type Errors = FieldErrors<FieldType.FullNameGroup>;

export function emptyFullNameGroupValues(): Values {
    return {
        last_name: "",
        first_name: "",
        patronymic: "",
    };
}

export function emptyFullNameGroupErrors(): Errors {
    return {
        non_field_errors: [],
        last_name: [],
        first_name: [],
        patronymic: [],
    };
}

export function requireFullNameGroup(schema: Schema, values: Values): Errors {
    const errors = emptyFullNameGroupErrors();

    if (schema.last_name === FieldState.Required && !values.last_name) {
        errors.last_name = [ErrorMsg.required];
    }
    if (schema.first_name === FieldState.Required && !values.first_name) {
        errors.first_name = [ErrorMsg.required];
    }
    if (schema.patronymic === FieldState.Required && !values.patronymic) {
        errors.patronymic = [ErrorMsg.required];
    }

    return errors;
}

export function serializeFullNameGroup(values: Values, request: RegistrationRequest) {
    request.body.full_name_group = values;
}

export function renderFullNameGroupSummary(schema: Schema, values: Values, errors: Errors): SummaryItem[] {
    const items: string[] = [];
    if (values.last_name) items.push(values.last_name);
    if (values.first_name) items.push(values.first_name);
    if (values.patronymic) items.push(values.patronymic);

    const isValid = FormModel[FieldType.FullNameGroup].isErrorsEmpty(errors);

    return [{key: "full_name", label: "ФИО", value: items.join(" "), hasErrors: !isValid}];
  }
