/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttrEnum } from './AttrEnum';
import {
    AttrEnumFromJSON,
    AttrEnumFromJSONTyped,
    AttrEnumToJSON,
} from './AttrEnum';

/**
 * 
 * @export
 * @interface RegistrationValidateError
 */
export interface RegistrationValidateError {
    /**
     * 
     * @type {AttrEnum}
     * @memberof RegistrationValidateError
     */
    attr: AttrEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationValidateError
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationValidateError
     */
    detail: string;
}

/**
 * Check if a given object implements the RegistrationValidateError interface.
 */
export function instanceOfRegistrationValidateError(value: object): value is RegistrationValidateError {
    if (!('attr' in value) || value['attr'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('detail' in value) || value['detail'] === undefined) return false;
    return true;
}

export function RegistrationValidateErrorFromJSON(json: any): RegistrationValidateError {
    return RegistrationValidateErrorFromJSONTyped(json, false);
}

export function RegistrationValidateErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationValidateError {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': AttrEnumFromJSON(json['attr']),
        'code': json['code'],
        'detail': json['detail'],
    };
}

export function RegistrationValidateErrorToJSON(value?: RegistrationValidateError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': AttrEnumToJSON(value['attr']),
        'code': value['code'],
        'detail': value['detail'],
    };
}

