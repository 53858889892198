/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldState } from './FieldState';
import {
    FieldStateFromJSON,
    FieldStateFromJSONTyped,
    FieldStateToJSON,
} from './FieldState';

/**
 * Схема full_address_group.
 * @export
 * @interface FullAddressGroupSchema
 */
export interface FullAddressGroupSchema {
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    country: FieldState;
    /**
     * ISO 3166-1 alpha-2.
     * @type {string}
     * @memberof FullAddressGroupSchema
     */
    default_country: string | null;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    region: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    settlement: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    street: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    house: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    building: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    postal_code: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullAddressGroupSchema
     */
    residential_unit: FieldState;
}

/**
 * Check if a given object implements the FullAddressGroupSchema interface.
 */
export function instanceOfFullAddressGroupSchema(value: object): value is FullAddressGroupSchema {
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('default_country' in value) || value['default_country'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    if (!('settlement' in value) || value['settlement'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('house' in value) || value['house'] === undefined) return false;
    if (!('building' in value) || value['building'] === undefined) return false;
    if (!('postal_code' in value) || value['postal_code'] === undefined) return false;
    if (!('residential_unit' in value) || value['residential_unit'] === undefined) return false;
    return true;
}

export function FullAddressGroupSchemaFromJSON(json: any): FullAddressGroupSchema {
    return FullAddressGroupSchemaFromJSONTyped(json, false);
}

export function FullAddressGroupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullAddressGroupSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'country': FieldStateFromJSON(json['country']),
        'default_country': json['default_country'],
        'region': FieldStateFromJSON(json['region']),
        'settlement': FieldStateFromJSON(json['settlement']),
        'street': FieldStateFromJSON(json['street']),
        'house': FieldStateFromJSON(json['house']),
        'building': FieldStateFromJSON(json['building']),
        'postal_code': FieldStateFromJSON(json['postal_code']),
        'residential_unit': FieldStateFromJSON(json['residential_unit']),
    };
}

export function FullAddressGroupSchemaToJSON(value?: FullAddressGroupSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country': FieldStateToJSON(value['country']),
        'default_country': value['default_country'],
        'region': FieldStateToJSON(value['region']),
        'settlement': FieldStateToJSON(value['settlement']),
        'street': FieldStateToJSON(value['street']),
        'house': FieldStateToJSON(value['house']),
        'building': FieldStateToJSON(value['building']),
        'postal_code': FieldStateToJSON(value['postal_code']),
        'residential_unit': FieldStateToJSON(value['residential_unit']),
    };
}

