import { Skeleton } from "@mantine/core";
import classes from "./FormStepperSkeleton.module.css";

interface Props {
  steps: number,
}

export default function FormStepperSkeleton({steps}: Props) {
  const nTimes = Array.from({length: steps}, (v, ix) => ix);

  return (
    <div className={classes["root"]}>
      {nTimes.map(ix => (
        <div key={ix} className={classes["step"]}>
          <Skeleton classNames={{root: classes["step-icon"]}} height={42} circle/>
          <Skeleton classNames={{root: classes["step-label"]}} height={16}/>
        </div>
      ))}
    </div>
  );
}
