/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryRegion } from './CountryRegion';
import {
    CountryRegionFromJSON,
    CountryRegionFromJSONTyped,
    CountryRegionToJSON,
} from './CountryRegion';

/**
 * Страна.
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * ISO 3166-1 alpha-2.
     * @type {string}
     * @memberof Country
     */
    iso_code: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    display_name: string;
    /**
     * 
     * @type {Array<CountryRegion>}
     * @memberof Country
     */
    readonly regions: Array<CountryRegion>;
}

/**
 * Check if a given object implements the Country interface.
 */
export function instanceOfCountry(value: object): value is Country {
    if (!('iso_code' in value) || value['iso_code'] === undefined) return false;
    if (!('display_name' in value) || value['display_name'] === undefined) return false;
    if (!('regions' in value) || value['regions'] === undefined) return false;
    return true;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if (json == null) {
        return json;
    }
    return {
        
        'iso_code': json['iso_code'],
        'display_name': json['display_name'],
        'regions': ((json['regions'] as Array<any>).map(CountryRegionFromJSON)),
    };
}

export function CountryToJSON(value?: Omit<Country, 'regions'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iso_code': value['iso_code'],
        'display_name': value['display_name'],
    };
}

