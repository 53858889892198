import { ReactNode } from "react";

export function joinBr(lines: string[]): ReactNode[] | undefined {
  if (lines.length === 0) {
    // Для удобства, чтобы пустой список нод был falsy.
    return undefined;
  }

  const nodes: ReactNode[] = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    nodes.push(line);

    const isLast = i === lines.length - 1;
    if (!isLast) {
      nodes.push(<br key={i}/>);
    }
  }
  return nodes;
}
