import {TextInput} from "@mantine/core";
import { useFormErrors, useFormValues } from "../../store/features/form/hooks";
import { ChangeEvent, useCallback } from "react";
import { joinBr } from "../../utils/react";
import { FieldSchema, FieldType } from "../../core/form/model";

export interface Props {
  fieldSchema: FieldSchema<FieldType.BirthPlace>,
}

export default function BirthPlaceField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.BirthPlace);
  const [errors, setErrors] = useFormErrors(FieldType.BirthPlace);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({value: event.target.value});
    setErrors([]);
  }, [setValues, setErrors]);

  return (
    <TextInput
      label="Место рождения"
      placeholder="Город"
      withAsterisk={fieldSchema.required}
      value={values.value || ""}
      onChange={onChange}
      error={joinBr(errors)}
    />
  );
}
