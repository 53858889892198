/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContactPhoneRequest } from './ContactPhoneRequest';
import {
    ContactPhoneRequestFromJSON,
    ContactPhoneRequestFromJSONTyped,
    ContactPhoneRequestToJSON,
} from './ContactPhoneRequest';
import type { GenderRequest } from './GenderRequest';
import {
    GenderRequestFromJSON,
    GenderRequestFromJSONTyped,
    GenderRequestToJSON,
} from './GenderRequest';
import type { FullAddressGroupRequest } from './FullAddressGroupRequest';
import {
    FullAddressGroupRequestFromJSON,
    FullAddressGroupRequestFromJSONTyped,
    FullAddressGroupRequestToJSON,
} from './FullAddressGroupRequest';
import type { IccIdRequest } from './IccIdRequest';
import {
    IccIdRequestFromJSON,
    IccIdRequestFromJSONTyped,
    IccIdRequestToJSON,
} from './IccIdRequest';
import type { PassportBiodataPhotoRequest } from './PassportBiodataPhotoRequest';
import {
    PassportBiodataPhotoRequestFromJSON,
    PassportBiodataPhotoRequestFromJSONTyped,
    PassportBiodataPhotoRequestToJSON,
} from './PassportBiodataPhotoRequest';
import type { ContactEmailRequest } from './ContactEmailRequest';
import {
    ContactEmailRequestFromJSON,
    ContactEmailRequestFromJSONTyped,
    ContactEmailRequestToJSON,
} from './ContactEmailRequest';
import type { SimNumberRequest } from './SimNumberRequest';
import {
    SimNumberRequestFromJSON,
    SimNumberRequestFromJSONTyped,
    SimNumberRequestToJSON,
} from './SimNumberRequest';
import type { BirthPlaceRequest } from './BirthPlaceRequest';
import {
    BirthPlaceRequestFromJSON,
    BirthPlaceRequestFromJSONTyped,
    BirthPlaceRequestToJSON,
} from './BirthPlaceRequest';
import type { PassportGroupRequest } from './PassportGroupRequest';
import {
    PassportGroupRequestFromJSON,
    PassportGroupRequestFromJSONTyped,
    PassportGroupRequestToJSON,
} from './PassportGroupRequest';
import type { PukRequest } from './PukRequest';
import {
    PukRequestFromJSON,
    PukRequestFromJSONTyped,
    PukRequestToJSON,
} from './PukRequest';
import type { BirthDateRequest } from './BirthDateRequest';
import {
    BirthDateRequestFromJSON,
    BirthDateRequestFromJSONTyped,
    BirthDateRequestToJSON,
} from './BirthDateRequest';
import type { FullNameGroupRequest } from './FullNameGroupRequest';
import {
    FullNameGroupRequestFromJSON,
    FullNameGroupRequestFromJSONTyped,
    FullNameGroupRequestToJSON,
} from './FullNameGroupRequest';
import type { PassportAddressPhotoRequest } from './PassportAddressPhotoRequest';
import {
    PassportAddressPhotoRequestFromJSON,
    PassportAddressPhotoRequestFromJSONTyped,
    PassportAddressPhotoRequestToJSON,
} from './PassportAddressPhotoRequest';

/**
 * Полный состав полей формы регистрации сим-карты.
 * @export
 * @interface FormDataRequest
 */
export interface FormDataRequest {
    /**
     * 
     * @type {IccIdRequest}
     * @memberof FormDataRequest
     */
    iccid?: IccIdRequest;
    /**
     * 
     * @type {SimNumberRequest}
     * @memberof FormDataRequest
     */
    sim_number?: SimNumberRequest;
    /**
     * 
     * @type {PukRequest}
     * @memberof FormDataRequest
     */
    puk?: PukRequest;
    /**
     * 
     * @type {FullNameGroupRequest}
     * @memberof FormDataRequest
     */
    full_name_group?: FullNameGroupRequest;
    /**
     * 
     * @type {GenderRequest}
     * @memberof FormDataRequest
     */
    gender?: GenderRequest;
    /**
     * 
     * @type {BirthDateRequest}
     * @memberof FormDataRequest
     */
    birth_date?: BirthDateRequest;
    /**
     * 
     * @type {BirthPlaceRequest}
     * @memberof FormDataRequest
     */
    birth_place?: BirthPlaceRequest;
    /**
     * 
     * @type {FullAddressGroupRequest}
     * @memberof FormDataRequest
     */
    full_address_group?: FullAddressGroupRequest;
    /**
     * 
     * @type {PassportGroupRequest}
     * @memberof FormDataRequest
     */
    passport_group?: PassportGroupRequest;
    /**
     * 
     * @type {PassportBiodataPhotoRequest}
     * @memberof FormDataRequest
     */
    passport_biodata_photo?: PassportBiodataPhotoRequest;
    /**
     * 
     * @type {PassportAddressPhotoRequest}
     * @memberof FormDataRequest
     */
    passport_address_photo?: PassportAddressPhotoRequest;
    /**
     * 
     * @type {ContactPhoneRequest}
     * @memberof FormDataRequest
     */
    contact_phone?: ContactPhoneRequest;
    /**
     * 
     * @type {ContactEmailRequest}
     * @memberof FormDataRequest
     */
    contact_email?: ContactEmailRequest;
}

/**
 * Check if a given object implements the FormDataRequest interface.
 */
export function instanceOfFormDataRequest(value: object): value is FormDataRequest {
    return true;
}

export function FormDataRequestFromJSON(json: any): FormDataRequest {
    return FormDataRequestFromJSONTyped(json, false);
}

export function FormDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDataRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'iccid': json['iccid'] == null ? undefined : IccIdRequestFromJSON(json['iccid']),
        'sim_number': json['sim_number'] == null ? undefined : SimNumberRequestFromJSON(json['sim_number']),
        'puk': json['puk'] == null ? undefined : PukRequestFromJSON(json['puk']),
        'full_name_group': json['full_name_group'] == null ? undefined : FullNameGroupRequestFromJSON(json['full_name_group']),
        'gender': json['gender'] == null ? undefined : GenderRequestFromJSON(json['gender']),
        'birth_date': json['birth_date'] == null ? undefined : BirthDateRequestFromJSON(json['birth_date']),
        'birth_place': json['birth_place'] == null ? undefined : BirthPlaceRequestFromJSON(json['birth_place']),
        'full_address_group': json['full_address_group'] == null ? undefined : FullAddressGroupRequestFromJSON(json['full_address_group']),
        'passport_group': json['passport_group'] == null ? undefined : PassportGroupRequestFromJSON(json['passport_group']),
        'passport_biodata_photo': json['passport_biodata_photo'] == null ? undefined : PassportBiodataPhotoRequestFromJSON(json['passport_biodata_photo']),
        'passport_address_photo': json['passport_address_photo'] == null ? undefined : PassportAddressPhotoRequestFromJSON(json['passport_address_photo']),
        'contact_phone': json['contact_phone'] == null ? undefined : ContactPhoneRequestFromJSON(json['contact_phone']),
        'contact_email': json['contact_email'] == null ? undefined : ContactEmailRequestFromJSON(json['contact_email']),
    };
}

export function FormDataRequestToJSON(value?: FormDataRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iccid': IccIdRequestToJSON(value['iccid']),
        'sim_number': SimNumberRequestToJSON(value['sim_number']),
        'puk': PukRequestToJSON(value['puk']),
        'full_name_group': FullNameGroupRequestToJSON(value['full_name_group']),
        'gender': GenderRequestToJSON(value['gender']),
        'birth_date': BirthDateRequestToJSON(value['birth_date']),
        'birth_place': BirthPlaceRequestToJSON(value['birth_place']),
        'full_address_group': FullAddressGroupRequestToJSON(value['full_address_group']),
        'passport_group': PassportGroupRequestToJSON(value['passport_group']),
        'passport_biodata_photo': PassportBiodataPhotoRequestToJSON(value['passport_biodata_photo']),
        'passport_address_photo': PassportAddressPhotoRequestToJSON(value['passport_address_photo']),
        'contact_phone': ContactPhoneRequestToJSON(value['contact_phone']),
        'contact_email': ContactEmailRequestToJSON(value['contact_email']),
    };
}

