import { useRouteError } from "react-router-dom"
import ErrorPage from "./ErrorPage"
import { useEffect } from "react"
import { isNotFoundError, isRouterErrorResponse } from "../core/router";
import { captureException } from "@sentry/react";
import NotFoundPage from "./NotFoundPage";

// https://github.com/getsentry/sentry-javascript/discussions/6912
export default function RouterErrorElement() {
  const routeError = useRouteError();
  const is404 = isNotFoundError(routeError) || (isRouterErrorResponse(routeError) && routeError.status === 404);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.warn(routeError);
    if (!is404) {
      captureException(routeError, { level: "fatal" })
    }
  }, [routeError, is404])

  if (is404) {
    return <NotFoundPage/>
  } else {
    return <ErrorPage/>
  }
}
