import {Anchor, AnchorProps, ElementProps} from "@mantine/core";
import classes from "./AnchorButton.module.css";

export interface Props extends AnchorProps, ElementProps<"button", keyof AnchorProps> {}

export default function AnchorButton({children, className, ...rest}: Props) {
  if (className) {
    className = `${className} ${classes.default}`;
  } else {
    className = classes.default;
  }
  return (
    <Anchor component="button" fz="" lh="" className={className} {...rest}>{children}</Anchor>
  );
}
