import { FieldSchema, FieldType, SummaryItem } from "../model";
import { ErrorMsg, FieldErrors } from "../errors";
import { CountryIso } from "../../codes";
import { onlyDigits } from "../../../utils/strings";
import { RegistrationRequest } from "../../api/types";
import { excludeKeys } from "../../../utils/data-structures";
import { FieldValues } from "../values";
import { References } from "../../../store/features/form/reducer";
import { FieldState } from "../../../client";

type Schema = FieldSchema<FieldType.FullAddressGroup>;
type Values = FieldValues<FieldType.FullAddressGroup>;
type Errors = FieldErrors<FieldType.FullAddressGroup>;

export function emptyFullAddressGroupValues(): Values {
    return {
        country: null,
        region: null,
        settlement_type: null,
        settlement_name: "",
        street_type: null,
        street_name: "",
        house_number: "",
        building_type: null,
        building_number: "",
        postal_code: "",
        residential_unit_type: null,
        residential_unit_number: "",
        omit_residential_unit: false,
    };
}

export function emptyFullAddressGroupErrors(): Errors {
    return {
        non_field_errors: [],
        country: [],
        region: [],
        settlement_type: [],
        settlement_name: [],
        street_type: [],
        street_name: [],
        house_number: [],
        building_type: [],
        building_number: [],
        postal_code: [],
        residential_unit_type: [],
        residential_unit_number: [],
    };
}

export function requireFullAddressGroup(schema: Schema, values: Values): Errors {
    const errors = emptyFullAddressGroupErrors();

    if (schema.country === FieldState.Required && !values.country) {
        errors.country = [ErrorMsg.required];
    }

    if (schema.region === FieldState.Required && !values.region) {
        errors.region = [ErrorMsg.required];
    }

    if (schema.settlement === FieldState.Required) {
        if (!values.settlement_type) {
            errors.settlement_type = [ErrorMsg.required];
        }
        if (!values.settlement_name) {
            errors.settlement_name = [ErrorMsg.required];
        }
    }

    if (schema.street === FieldState.Required) {
        if (!values.street_type) {
            errors.street_type = [ErrorMsg.required];
        }
        if (!values.street_name) {
            errors.street_name = [ErrorMsg.required];
        }
    }

    if (schema.house === FieldState.Required && !values.house_number) {
        errors.house_number = [ErrorMsg.required];
    }

    if (schema.building === FieldState.Required) {
        if (!values.building_type) {
            errors.building_type = [ErrorMsg.required];
        }
        if (!values.building_number) {
            errors.building_number = [ErrorMsg.required];
        }
    }

    if (schema.postal_code === FieldState.Required && !values.postal_code) {
        errors.postal_code = [ErrorMsg.required];
    }

    if (schema.residential_unit === FieldState.Required) {
        if (!values.residential_unit_type) {
            errors.residential_unit_type = [ErrorMsg.required];
        }
        if (!values.residential_unit_number) {
            errors.residential_unit_number = [ErrorMsg.required];
        }
    }

    return errors;
}

export function validateFullAddressGroup(schema: Schema, values: Values): Errors {
    const errors = emptyFullAddressGroupErrors();

    let isRu = false;
    if (schema.country === FieldState.Hidden) {
        isRu = schema.default_country === CountryIso.RU;
    } else {
        isRu = values.country === CountryIso.RU;
    }

    if (isRu) {
        if (values.postal_code && onlyDigits(values.postal_code).length !== 6) {
            errors.postal_code = ["Индекс должен состоять из 6 цифр."]
        }
    }

    return errors;
}

export function serializeFullAddressGroup(values: Values, request: RegistrationRequest) {
    request.body.full_address_group = excludeKeys(values, ["omit_residential_unit"]);

    const group = request.body.full_address_group;
    if (!group.settlement_type || !group.settlement_name) {
        group.settlement_type = null;
        group.settlement_name = null;
    }
    if (!group.street_type || !group.street_name) {
        group.street_type = null;
        group.street_name = null;
    }
    if (!group.building_type || !group.building_number) {
        group.building_type = null;
        group.building_number = null;
    }
    if (!group.residential_unit_type || !group.residential_unit_number) {
        group.residential_unit_type = null;
        group.residential_unit_number = null;
    }
}

export function renderFullAddressGroupSummary(schema: Schema, values: Values, errors: Errors, references: References): SummaryItem[] {
    const items: SummaryItem[] = [];
    const hasNonFieldErrors = errors.non_field_errors.length > 0;

    if (schema.country !== FieldState.Hidden) {
        const country = references.countries.find(c => c.iso_code === values.country);
        items.push({
            key: "country",
            label: "Страна",
            value: country?.display_name || null,
            hasErrors: errors.country.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.region !== FieldState.Hidden) {
        const region = references.countries.flatMap(c => c.regions).find(r => r.name === values.region);
        items.push({
            key: "region",
            label: "Область",
            value: region?.display_name || null,
            hasErrors: errors.region.length > 0 || hasNonFieldErrors,
        });
    }

    const typedName = (type: {abbr: string} | undefined, name: string | null): string | null => {
        if (!name) return null;
        if (!type) return name;
        return `${type.abbr} ${name}`;
    };

    if (schema.settlement !== FieldState.Hidden) {
        const settlementType = references.settlementTypes.find(t => t.id === values.settlement_type);
        items.push({
            key: "settlement",
            label: "Населенный пункт",
            value: typedName(settlementType, values.settlement_name),
            hasErrors: errors.settlement_type.length > 0 || errors.settlement_name.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.street !== FieldState.Hidden) {
        const streetType = references.streetTypes.find(t => t.id === values.street_type);
        items.push({
            key: "street",
            label: "Улица",
            value: typedName(streetType, values.street_name),
            hasErrors: errors.street_type.length > 0 || errors.street_name.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.house !== FieldState.Hidden) {
        items.push({
            key: "house_number",
            label: "Дом",
            value: values.house_number,
            hasErrors: errors.house_number.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.building !== FieldState.Hidden) {
        const buildingType = references.buildingTypes.find(t => t.id === values.building_type);
        items.push({
            key: "building",
            label: "Строение",
            value: typedName(buildingType, values.building_number),
            hasErrors: errors.building_type.length > 0 || errors.building_number.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.postal_code !== FieldState.Hidden) {
        items.push({
            key: "postal_code",
            label: "Индекс",
            value: values.postal_code,
            hasErrors: errors.postal_code.length > 0 || hasNonFieldErrors,
        });
    }

    if (schema.residential_unit !== FieldState.Hidden) {
        const unitType = references.residentialUnitTypes.find(t => t.id === values.residential_unit_type);
        items.push({
            key: "residential_unit",
            label: "Помещение",
            value: typedName(unitType, values.residential_unit_number),
            hasErrors: errors.residential_unit_type.length > 0 || errors.residential_unit_number.length > 0 || hasNonFieldErrors,
        });
    }

    return items;
}
