import {TextInput} from "@mantine/core";
import { useFormErrors, useFormValues } from "../../store/features/form/hooks";
import { ChangeEvent, useCallback } from "react";
import { joinBr } from "../../utils/react";
import { MaskitoOptions } from "@maskito/core";
import { phoneMask } from "../../utils/masks";
import { useMaskito } from "@maskito/react";
import { FieldSchema, FieldType } from "../../core/form/model";

export interface Props {
  fieldSchema: FieldSchema<FieldType.ContactPhone>,
}

const maskOptions: MaskitoOptions = {
  mask: phoneMask,
}

export default function ContactPhoneField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.ContactPhone);
  const [errors, setErrors] = useFormErrors(FieldType.ContactPhone);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({value: event.target.value});
    setErrors([]);
  }, [setValues, setErrors]);

  const inputRef = useMaskito({options: maskOptions});

  return (
    <TextInput
      type="tel"
      label="Телефон для связи"
      placeholder="+7 (___) ___-____"
      withAsterisk={fieldSchema.required}
      value={values.value || ""}
      onInput={onChange}
      error={joinBr(errors)}
      ref={inputRef}
    />
  );
}
