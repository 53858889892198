import { ElementProps, TextInput, TextInputProps } from "@mantine/core";
import { ChangeEvent, useCallback, useState } from "react";
import { TrueDate, formatTrueDate, parseTrueDate } from "../utils/dates";
import { useMaskito } from "@maskito/react";
import { dateMaskOptions } from "../utils/masks";

export interface Props extends Omit<TextInputProps, 'value' | 'onChange'>, ElementProps<'div', keyof TextInputProps> {
  value: TrueDate | null,
  onChange: (v: TrueDate | null) => void,
}

export default function TrueDateInput({value, onChange, ...rest}: Props) {
  const formattedValue = value ? formatTrueDate(value) : undefined;
  const [raw, setRaw] = useState(formattedValue || "");
  const onInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRaw(event.target.value);
    const td = parseTrueDate(event.target.value);
    onChange(td || null);
  }, [onChange]);
  const onBlur = useCallback(() => {
    setRaw(formattedValue || "");
  }, [formattedValue]);

  const inputRef = useMaskito({options: dateMaskOptions});

  return (
    <TextInput
      value={raw}
      onInput={onInput}
      onBlur={onBlur}
      ref={inputRef}
      {...rest}
    />
  );
}
