import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "../../..";
import { FormSliceState } from "../reducer";
import { FormStepKey } from "../../../../core/form/steps";
import { FormErrors } from "../../../../core/form/errors";
import { getFormContext } from "../../../../core/form/render-context";
import { RegistrationApi, RegistrationSubmitValidationErrorFromJSON } from "../../../../client";
import { clientConfig } from "../../../../core/api/client";
import { isHTTPError } from "../../../../core/api/errors";
import { clearStepErrors, deserializeFormErrors, emptyFormErrors, fullValidateStep, getFormSchemaFieldTypes, isFormErrorsEmpty, serializeToRequest } from "../../../../core/form/utils";

const submit = createAsyncThunk<FormErrors | null, void, AsyncThunkConfig>(
  "form/submit",
  async (payload, thunkAPI): Promise<FormErrors | null> => {
    const state = thunkAPI.getState();
    const formState = state.form.formState.value;
    if (!formState) {
      // eslint-disable-next-line no-console
      console.warn("FormState requested by submit but is not loaded.");
      return null;
    }

    // Валидируем на клиенте.
    const formContext = getFormContext(formState.schema.schema);
    const stepContext = formContext.stepContexts.find(c => c.step.key === FormStepKey.Confirm)!;
    const clientSideErrors = fullValidateStep(formState.schema.schema, formState.values, stepContext);
    if (!isFormErrorsEmpty(clientSideErrors)) {
      return {...emptyFormErrors(), ...clientSideErrors};
    }

    // Отправляем на сервер.
    const fieldTypes = getFormSchemaFieldTypes(formState.schema.schema);
    const request = serializeToRequest(formState.provider.name, formState.values, fieldTypes);
    const api = new RegistrationApi(clientConfig);
    try {
      await api.registrationSubmit(request);
    } catch(e) {
      if (!isHTTPError(e)) {
        throw e;
      }

      const resp = e.asValidationError(RegistrationSubmitValidationErrorFromJSON);
      if (!resp) {
        throw e;
      }

      return deserializeFormErrors(resp);
    }

    return null;
  },
);
export default submit;

export function addSubmitCases(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addCase(submit.pending, state => {
    const formState = state.formState.value;
    if (!formState) return;

    formState.stepStates[FormStepKey.Confirm].isValidating = true;
    formState.stepStates[FormStepKey.Confirm].validationFailureMessage = undefined;
    formState.errors = clearStepErrors(formState.errors, FormStepKey.Confirm);
  });
  builder.addCase(submit.fulfilled, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    if (action.payload) {
      formState.errors = action.payload;
    } else {
      formState.errors = emptyFormErrors();
    }
  });
  builder.addCase(submit.rejected, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    /* eslint-disable-next-line no-console */
    console.error("submit rejected", action.error);
    formState.stepStates[FormStepKey.Confirm].validationFailureMessage = "Что-то пошло не так. Повторите попытку позже.";
  });
}

export function addSubmitMatchers(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addMatcher(submit.settled, state => {
    const formState = state.formState.value;
    if (!formState) return;

    formState.stepStates[FormStepKey.Confirm].isValidating = false;
  });
}
