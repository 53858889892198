import {Button, ButtonProps, ElementProps, Image, rem} from "@mantine/core";
import {Provider} from "../client";
import {PropsWithChildren} from "react";
import { Link } from "react-router-dom";

export interface Props extends ButtonProps, ElementProps<"a", keyof ButtonProps> {
  provider: Provider,
}

export default function ProviderButton(props: PropsWithChildren<Props>) {
  let leftSection = null;
  if (props.provider.icon) {
    leftSection = <ProviderButton.Icon src={props.provider.icon}/>;
  }

  return (
    <Button
      component={Link}
      to={`/register/${props.provider.name}/`}
      leftSection={leftSection}
      variant="default"
      {...props}
    >{props.provider.display_name}</Button>
  );
}

export interface IconProps {
  src: string,
}

ProviderButton.Icon = function(props: IconProps) {
  return (
    <Image src={props.src} w={rem(24)} h={rem(24)} fit="contain"/>
  );
}
