import { Button, Group, Input, Stack, rem } from "@mantine/core"
import { FormStepKey } from "../core/form/steps";
import { useAppSelector } from "../store";
import { getFormContext } from "../core/form/render-context";
import { isStepFilled } from "../core/form/utils";

export interface Props {
  stepKey: FormStepKey,
  onPrev?: () => void,
  onNext?: () => void,
}

export default function FormStepForm({stepKey, onPrev, onNext}: Props) {
  const formState = useAppSelector(state => state.form.formState.value!);
  const formContext = getFormContext(formState.schema.schema);
  const stepIx = formContext.stepContexts.findIndex(ctx => ctx.step.key === stepKey);
  const stepCtx = formContext.stepContexts[stepIx]
  if (!stepCtx) {
    throw new Error(`${stepKey} not found in form context`);
  }

  const stepState = formState.stepStates[stepKey];
  const isFilled = isStepFilled(formState.schema.schema, formState.values, stepCtx);

  return (
    <div>
      <Stack mb={rem(20)}>
        {stepCtx.fieldContexts.map(fieldCtx => fieldCtx.render())}
      </Stack>
      {stepState.validationFailureMessage && (
        <Input.Error mb={rem(20)}>{stepState.validationFailureMessage}</Input.Error>
      )}
      <Group gap={rem(8)}>
        {stepIx > 0 && (
          <Button variant="outline" disabled={stepState.isValidating} onClick={() => onPrev && onPrev()}>Назад</Button>
        )}
        <Button loading={stepState.isValidating} disabled={!isFilled} onClick={() => onNext && onNext()}>Далее</Button>
      </Group>
    </div>
  );
}
