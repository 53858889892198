import {TextInput} from "@mantine/core";
import { useFormErrors, useFormValues } from "../../store/features/form/hooks";
import { ChangeEvent, useCallback } from "react";
import { joinBr } from "../../utils/react";
import { FieldSchema, FieldType } from "../../core/form/model";

export interface Props {
  fieldSchema: FieldSchema<FieldType.ContactEmail>,
}

export default function ContactEmailField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.ContactEmail);
  const [errors, setErrors] = useFormErrors(FieldType.ContactEmail);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({value: event.target.value});
    setErrors([]);
  }, [setValues, setErrors]);

  return (
    <TextInput
      type="email"
      label="Email"
      placeholder="____@____"
      withAsterisk={fieldSchema.required}
      value={values.value || ""}
      onChange={onChange}
      error={joinBr(errors)}
    />
  );
}
