import {Input, rem, Stack, TextInput} from "@mantine/core";
import {useFormErrors, useFormValues} from "../../store/features/form/hooks.ts";
import {ChangeEvent, useCallback} from "react";
import { FieldSchema, FieldType } from "../../core/form/model.ts";
import { joinBr } from "../../utils/react.tsx";
import { FieldState } from "../../client/index.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.FullNameGroup>,
}

export default function FullNameFieldGroup({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.FullNameGroup);
  const [errors, setErrors] = useFormErrors(FieldType.FullNameGroup);
  const onLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, last_name: event.target.value});
    setErrors({...errors, last_name: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const onFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, first_name: event.target.value});
    setErrors({...errors, first_name: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const onPatronymicChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, patronymic: event.target.value});
    setErrors({...errors, patronymic: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const hasNonFieldErrors = errors.non_field_errors.length > 0;

  const withAsterisk = (
    fieldSchema.last_name === FieldState.Required ||
    fieldSchema.first_name === FieldState.Required ||
    fieldSchema.patronymic === FieldState.Required
  );

  return (
    <div>
      <Input.Label required={withAsterisk}>ФИО</Input.Label>
      <Stack gap={rem(8)}>
        {fieldSchema.last_name !== FieldState.Hidden &&
          <TextInput
            placeholder="Фамилия"
            value={values.last_name || ""}
            onChange={onLastNameChange}
            error={joinBr(errors.last_name) || hasNonFieldErrors}
          />
        }
        {fieldSchema.first_name !== FieldState.Hidden &&
          <TextInput
            placeholder="Имя"
            value={values.first_name || ""}
            onChange={onFirstNameChange}
            error={joinBr(errors.first_name) || hasNonFieldErrors}
          />
        }
        {fieldSchema.patronymic !== FieldState.Hidden &&
          <TextInput
            placeholder="Отчество (если есть)"
            value={values.patronymic || ""}
            onChange={onPatronymicChange}
            error={joinBr(errors.patronymic) || hasNonFieldErrors}
          />
        }
      </Stack>
      {errors.non_field_errors.length > 0 &&
        <Input.Error mt={rem(5)}>{joinBr(errors.non_field_errors)}</Input.Error>
      }
    </div>
  );
}
