import {SiteSettingsProvider} from "../client";
import { getSiteSettings } from "../core/site-settings.ts";
import ProviderButton from "./ProviderButton.tsx";
import classes from "./StartBlock.module.css";
import {Button, Divider, rem, Text, useMatches} from "@mantine/core";
import {Link} from "react-router-dom";

export default function StartBlock () {
  const siteSettings = getSiteSettings();
  const m24_32 = useMatches({
    base: rem(24),
    md: rem(32),
  });

  if (siteSettings.site_providers.length === 1) {
    const siteProvider = siteSettings.site_providers[0];
    return (
      <div>
        <Button component={Link} to={`/register/${siteProvider.provider.name}/`}>Начать</Button>
      </div>
    );
  }

  return (
    <div>
      <Divider mb={m24_32}/>
      <Text mb={rem(16)}>Чтобы начать, выберите оператора вашей сим-карты:</Text>
      <div className={classes.buttonGroup}>
        {siteSettings.site_providers.map(({provider}: SiteSettingsProvider) => (
          <ProviderButton key={provider.name} provider={provider}/>
        ))}
      </div>
    </div>
  );
}
