import { RegistrationRequest } from "../../api/types";
import { globalFileStorage } from "../../file-storage";
import { FieldType } from "../model";
import { FieldValues } from "../values";

type Values = FieldValues<FieldType.PassportAddressPhoto>;

export function serializePassportAddressPhoto(values: Values, request: RegistrationRequest) {
    const fileId = values.fileId;
    const file = fileId ? globalFileStorage.get(fileId) : null;
    if (file) {
        request.body.passport_address_photo = {file: true};
        request.passportAddressPhoto = file;
    } else {
        request.body.passport_address_photo = {file: false};
    }
}
