/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Поле IccId.
 * @export
 * @interface IccIdRequest
 */
export interface IccIdRequest {
    /**
     * 
     * @type {string}
     * @memberof IccIdRequest
     */
    value: string | null;
}

/**
 * Check if a given object implements the IccIdRequest interface.
 */
export function instanceOfIccIdRequest(value: object): value is IccIdRequest {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function IccIdRequestFromJSON(json: any): IccIdRequest {
    return IccIdRequestFromJSONTyped(json, false);
}

export function IccIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IccIdRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function IccIdRequestToJSON(value?: IccIdRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

