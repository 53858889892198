import { SiteSettings } from "../client";

const emptySiteSettings: SiteSettings = {
  landing_content: [],
  success_content: [],
  site_providers: [],
  footer_links: [],
  contact_links: [],
};

export function getSiteSettings(): SiteSettings {
  const element = document.getElementById("site-settings");
  if (!element || !element.textContent) {
    return emptySiteSettings;
  }

  return JSON.parse(element.textContent) as SiteSettings;
}
