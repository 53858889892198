/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChoiceEnum } from './ChoiceEnum';
import {
    ChoiceEnumFromJSON,
    ChoiceEnumFromJSONTyped,
    ChoiceEnumToJSON,
} from './ChoiceEnum';

/**
 * Поле пола.
 * @export
 * @interface GenderRequest
 */
export interface GenderRequest {
    /**
     * 
     * @type {ChoiceEnum}
     * @memberof GenderRequest
     */
    choice: ChoiceEnum | null;
}

/**
 * Check if a given object implements the GenderRequest interface.
 */
export function instanceOfGenderRequest(value: object): value is GenderRequest {
    if (!('choice' in value) || value['choice'] === undefined) return false;
    return true;
}

export function GenderRequestFromJSON(json: any): GenderRequest {
    return GenderRequestFromJSONTyped(json, false);
}

export function GenderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'choice': ChoiceEnumFromJSON(json['choice']),
    };
}

export function GenderRequestToJSON(value?: GenderRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'choice': ChoiceEnumToJSON(value['choice']),
    };
}

