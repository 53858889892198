import {Input, Select, Stack} from "@mantine/core";
import {Group, Radio} from "@mantine/core";
import {ChangeEvent, useCallback} from "react";
import {TextInput} from "@mantine/core";
import { useFormErrors, useFormValues, useReferences } from "../../store/features/form/hooks";
import { joinBr } from "../../utils/react";
import TrueDateInput from "../TrueDateInput";
import { TrueDate } from "../../utils/dates";
import { MaskitoOptions } from "@maskito/core";
import { d } from "../../utils/masks";
import { useMaskito } from "@maskito/react";
import { FieldSchema, FieldType } from "../../core/form/model";
import { FieldState } from "../../client";
import { CountryIso } from "../../core/codes";

export interface Props {
  fieldSchema: FieldSchema<FieldType.PassportGroup>,
}

const RUSSIAN = "0";
const FOREIGN = "1";

const ruSeriesMaskOptions: MaskitoOptions = {
  mask: /^\d{0,4}$/,
};
const ruNumberMaskOptions: MaskitoOptions = {
  mask: /^\d{0,6}$/,
};
const ruSubdivisionCodeMaskOptions: MaskitoOptions = {
  mask: [d, d, d, "-", d, d, d],
};

export default function PassportFieldGroup({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.PassportGroup);
  const [errors, setErrors] = useFormErrors(FieldType.PassportGroup);
  const hasNonFieldErrors = errors.non_field_errors.length > 0;

  const citizenshipValue = values.foreign_citizenship ? FOREIGN : RUSSIAN;
  const onCitizenshipChange = useCallback((value: string) => {
    if (value === FOREIGN) {
      setValues({...values, foreign_citizenship: true});
    } else if (value === RUSSIAN) {
      setValues({...values, foreign_citizenship: false});
    } else {
      setValues({...values, foreign_citizenship: undefined});
    }
    setErrors({
      non_field_errors: [],
      foreign_citizenship: [],
      citizenship_country: [],
      series: [],
      number: [],
      issue_date: [],
      issued_by: [],
      expiration_date: [],
      subdivision_code: [],
    });
  }, [values, setValues, setErrors]);

  return (
    <Stack>
      {fieldSchema.foreign_enabled &&
        <Radio.Group
          label="Гражданство"
          value={citizenshipValue}
          onChange={onCitizenshipChange}
          error={joinBr(errors.foreign_citizenship) || hasNonFieldErrors}
        >
          <Group mt="xs">
            <Radio value={RUSSIAN} label="Гражданин России"/>
            <Radio value={FOREIGN} label="Иностранный гражданин"/>
          </Group>
        </Radio.Group>
      }
      {citizenshipValue === RUSSIAN && <NationalPassportFieldGroup fieldSchema={fieldSchema}/>}
      {citizenshipValue === FOREIGN && <ForeignPassportFieldGroup fieldSchema={fieldSchema}/>}
    </Stack>
  );
}

function NationalPassportFieldGroup({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.PassportGroup);
  const [errors, setErrors] = useFormErrors(FieldType.PassportGroup);
  const hasNonFieldErrors = errors.non_field_errors.length > 0;

  const onSeriesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, series: event.target.value});
    setErrors({...errors, series: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const seriesRef = useMaskito({options: ruSeriesMaskOptions});

  const onNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, number: event.target.value});
    setErrors({...errors, number: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const numberRef = useMaskito({options: ruNumberMaskOptions});

  const onIssuedByChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, issued_by: event.target.value});
    setErrors({...errors, issued_by: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onIssueDateChange = useCallback((d: TrueDate | null) => {
    setValues({...values, issue_date: d})
    setErrors({...errors, issue_date: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onSubdivisionCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, subdivision_code: event.target.value});
    setErrors({...errors, subdivision_code: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);
  const subdivisionCodeRef = useMaskito({options: ruSubdivisionCodeMaskOptions});

  return (
    <Stack>
      {(fieldSchema.series !== FieldState.Hidden || fieldSchema.number !== FieldState.Hidden) &&
        <Group grow align="start">
          {fieldSchema.series !== FieldState.Hidden &&
            <TextInput
              label="Серия"
              withAsterisk={fieldSchema.series === FieldState.Required}
              value={values.series || ""}
              onInput={onSeriesChange}
              error={joinBr(errors.series) || hasNonFieldErrors}
              ref={seriesRef}
              inputMode="numeric"
            />
          }
          {fieldSchema.number !== FieldState.Hidden &&
            <TextInput
              label="Номер"
              withAsterisk={fieldSchema.number === FieldState.Required}
              value={values.number || ""}
              onInput={onNumberChange}
              error={joinBr(errors.number) || hasNonFieldErrors}
              ref={numberRef}
              inputMode="numeric"
            />
          }
        </Group>
      }
      {fieldSchema.issued_by !== FieldState.Hidden &&
        <TextInput
          label="Кем выдан"
          withAsterisk={fieldSchema.issued_by === FieldState.Required}
          value={values.issued_by || ""}
          onChange={onIssuedByChange}
          error={joinBr(errors.issued_by) || hasNonFieldErrors}
        />
      }
      {(fieldSchema.issue_date !== FieldState.Hidden || fieldSchema.subdivision_code !== FieldState.Hidden) &&
        <Group grow align="start">
          {fieldSchema.issue_date !== FieldState.Hidden &&
            <TrueDateInput
              placeholder="__.__.____"
              label="Дата выдачи"
              withAsterisk={fieldSchema.issue_date === FieldState.Required}
              value={values.issue_date}
              onChange={onIssueDateChange}
              error={joinBr(errors.issue_date) || hasNonFieldErrors}
            />
          }
          {fieldSchema.subdivision_code !== FieldState.Hidden &&
            <TextInput
              label="Код подразделения"
              placeholder="___-___"
              withAsterisk={fieldSchema.subdivision_code === FieldState.Required}
              value={values.subdivision_code || ""}
              onInput={onSubdivisionCodeChange}
              error={joinBr(errors.subdivision_code) || hasNonFieldErrors}
              ref={subdivisionCodeRef}
              inputMode="numeric"
            />
          }
        </Group>
      }
      {errors.non_field_errors.length > 0 && (
        <Input.Error>{joinBr(errors.non_field_errors)}</Input.Error>
      )}
    </Stack>
  );
}

function ForeignPassportFieldGroup({fieldSchema}: Props) {
  const {countries} = useReferences();
  const [values, setValues] = useFormValues(FieldType.PassportGroup);
  const [errors, setErrors] = useFormErrors(FieldType.PassportGroup);
  const hasNonFieldErrors = errors.non_field_errors.length > 0;

  const countriesOptions = countries.filter(c => c.iso_code !== CountryIso.RU).map(c => ({label: c.display_name, value: c.iso_code}));
  const onCountryChange = useCallback((isoCode: string | null) => {
    setValues({...values, citizenship_country: isoCode});
    setErrors({...errors, citizenship_country: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, number: event.target.value});
    setErrors({...errors, number: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onIssuedByChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, issued_by: event.target.value});
    setErrors({...errors, issued_by: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onIssueDateChange = useCallback((d: TrueDate | null) => {
    setValues({...values, issue_date: d})
    setErrors({...errors, issue_date: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onExpirationDateChange = useCallback((d: TrueDate | null) => {
    setValues({...values, expiration_date: d})
    setErrors({...errors, expiration_date: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  return (
    <Stack>
      {fieldSchema.foreign_citizenship_country !== FieldState.Hidden &&
        <Select
          label="Страна"
          withAsterisk={fieldSchema.foreign_citizenship_country === FieldState.Required}
          data={countriesOptions}
          value={values.citizenship_country}
          onChange={onCountryChange}
          placeholder="Выберите"
          error={joinBr(errors.citizenship_country) || hasNonFieldErrors}
        />
      }
      {fieldSchema.foreign_number !== FieldState.Hidden &&
        <TextInput
          label="Номер"
          withAsterisk={fieldSchema.foreign_number === FieldState.Required}
          value={values.number || ""}
          onInput={onNumberChange}
          error={joinBr(errors.number) || hasNonFieldErrors}
        />
      }
      {fieldSchema.foreign_issued_by !== FieldState.Hidden &&
        <TextInput
          label="Кем выдан"
          withAsterisk={fieldSchema.foreign_issued_by === FieldState.Required}
          value={values.issued_by || ""}
          onChange={onIssuedByChange}
          error={joinBr(errors.issued_by) || hasNonFieldErrors}
        />
      }
      {fieldSchema.foreign_issue_date !== FieldState.Hidden &&
        <TrueDateInput
          placeholder="__.__.____"
          label="Дата выдачи"
          withAsterisk={fieldSchema.foreign_issue_date === FieldState.Required}
          value={values.issue_date}
          onChange={onIssueDateChange}
          error={joinBr(errors.issue_date) || hasNonFieldErrors}
        />
      }
      {fieldSchema.foreign_expiration_date !== FieldState.Hidden &&
        <TrueDateInput
          placeholder="__.__.____"
          label="Дата окончания срока действия"
          withAsterisk={fieldSchema.foreign_expiration_date === FieldState.Required}
          value={values.expiration_date}
          onChange={onExpirationDateChange}
          error={joinBr(errors.expiration_date) || hasNonFieldErrors}
        />
      }
      {errors.non_field_errors.length > 0 && (
        <Input.Error>{joinBr(errors.non_field_errors)}</Input.Error>
      )}
    </Stack>
  );
}
