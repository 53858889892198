/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Группа полей с полным адресом регистрации.
 * @export
 * @interface FullAddressGroupRequest
 */
export interface FullAddressGroupRequest {
    /**
     * ISO 3166-1 alpha-2.
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    country: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    region: string | null;
    /**
     * 
     * @type {number}
     * @memberof FullAddressGroupRequest
     */
    settlement_type: number | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    settlement_name: string | null;
    /**
     * 
     * @type {number}
     * @memberof FullAddressGroupRequest
     */
    street_type: number | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    street_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    house_number: string | null;
    /**
     * 
     * @type {number}
     * @memberof FullAddressGroupRequest
     */
    building_type: number | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    building_number: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    postal_code: string | null;
    /**
     * 
     * @type {number}
     * @memberof FullAddressGroupRequest
     */
    residential_unit_type: number | null;
    /**
     * 
     * @type {string}
     * @memberof FullAddressGroupRequest
     */
    residential_unit_number: string | null;
}

/**
 * Check if a given object implements the FullAddressGroupRequest interface.
 */
export function instanceOfFullAddressGroupRequest(value: object): value is FullAddressGroupRequest {
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    if (!('settlement_type' in value) || value['settlement_type'] === undefined) return false;
    if (!('settlement_name' in value) || value['settlement_name'] === undefined) return false;
    if (!('street_type' in value) || value['street_type'] === undefined) return false;
    if (!('street_name' in value) || value['street_name'] === undefined) return false;
    if (!('house_number' in value) || value['house_number'] === undefined) return false;
    if (!('building_type' in value) || value['building_type'] === undefined) return false;
    if (!('building_number' in value) || value['building_number'] === undefined) return false;
    if (!('postal_code' in value) || value['postal_code'] === undefined) return false;
    if (!('residential_unit_type' in value) || value['residential_unit_type'] === undefined) return false;
    if (!('residential_unit_number' in value) || value['residential_unit_number'] === undefined) return false;
    return true;
}

export function FullAddressGroupRequestFromJSON(json: any): FullAddressGroupRequest {
    return FullAddressGroupRequestFromJSONTyped(json, false);
}

export function FullAddressGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullAddressGroupRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'],
        'region': json['region'],
        'settlement_type': json['settlement_type'],
        'settlement_name': json['settlement_name'],
        'street_type': json['street_type'],
        'street_name': json['street_name'],
        'house_number': json['house_number'],
        'building_type': json['building_type'],
        'building_number': json['building_number'],
        'postal_code': json['postal_code'],
        'residential_unit_type': json['residential_unit_type'],
        'residential_unit_number': json['residential_unit_number'],
    };
}

export function FullAddressGroupRequestToJSON(value?: FullAddressGroupRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country': value['country'],
        'region': value['region'],
        'settlement_type': value['settlement_type'],
        'settlement_name': value['settlement_name'],
        'street_type': value['street_type'],
        'street_name': value['street_name'],
        'house_number': value['house_number'],
        'building_type': value['building_type'],
        'building_number': value['building_number'],
        'postal_code': value['postal_code'],
        'residential_unit_type': value['residential_unit_type'],
        'residential_unit_number': value['residential_unit_number'],
    };
}

