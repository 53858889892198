/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Регион внутри CountrySerializer.
 * @export
 * @interface CountryRegion
 */
export interface CountryRegion {
    /**
     * 
     * @type {string}
     * @memberof CountryRegion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CountryRegion
     */
    display_name: string;
}

/**
 * Check if a given object implements the CountryRegion interface.
 */
export function instanceOfCountryRegion(value: object): value is CountryRegion {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('display_name' in value) || value['display_name'] === undefined) return false;
    return true;
}

export function CountryRegionFromJSON(json: any): CountryRegion {
    return CountryRegionFromJSONTyped(json, false);
}

export function CountryRegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryRegion {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'display_name': json['display_name'],
    };
}

export function CountryRegionToJSON(value?: CountryRegion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'display_name': value['display_name'],
    };
}

