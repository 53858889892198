import exampleImage from "../../assets/passport-address-example.svg"
import BasePhotoField from "./BasePhotoField.tsx";
import { useFormErrors, useFormValues } from "../../store/features/form/hooks.ts";
import { useCallback } from "react";
import { joinBr } from "../../utils/react.tsx";
import { FieldSchema, FieldType } from "../../core/form/model.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.PassportAddressPhoto>,
}

export default function PassportAddressPhotoField({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.PassportAddressPhoto);
  const [errors, setErrors] = useFormErrors(FieldType.PassportAddressPhoto);
  const onChange = useCallback((fileId: string | null) => {
    setValues({...values, fileId});
    setErrors([]);
  }, [values, setValues, setErrors]);

  return (
    <BasePhotoField
      label="Фото разворота с текущей пропиской"
      description="Прикрепите фото или скан разворота паспорта с вашим текущим адресом регистрации. Все буквы должны хорошо читаться."
      withAsterisk={fieldSchema.required}
      exampleSrc={exampleImage}
      fileId={values.fileId}
      onChange={onChange}
      error={joinBr(errors)}
    />
  );
}
