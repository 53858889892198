/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldState } from './FieldState';
import {
    FieldStateFromJSON,
    FieldStateFromJSONTyped,
    FieldStateToJSON,
} from './FieldState';

/**
 * Схема поля full_name_group.
 * @export
 * @interface FullNameGroupSchema
 */
export interface FullNameGroupSchema {
    /**
     * 
     * @type {FieldState}
     * @memberof FullNameGroupSchema
     */
    last_name: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullNameGroupSchema
     */
    first_name: FieldState;
    /**
     * 
     * @type {FieldState}
     * @memberof FullNameGroupSchema
     */
    patronymic: FieldState;
}

/**
 * Check if a given object implements the FullNameGroupSchema interface.
 */
export function instanceOfFullNameGroupSchema(value: object): value is FullNameGroupSchema {
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('patronymic' in value) || value['patronymic'] === undefined) return false;
    return true;
}

export function FullNameGroupSchemaFromJSON(json: any): FullNameGroupSchema {
    return FullNameGroupSchemaFromJSONTyped(json, false);
}

export function FullNameGroupSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullNameGroupSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'last_name': FieldStateFromJSON(json['last_name']),
        'first_name': FieldStateFromJSON(json['first_name']),
        'patronymic': FieldStateFromJSON(json['patronymic']),
    };
}

export function FullNameGroupSchemaToJSON(value?: FullNameGroupSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'last_name': FieldStateToJSON(value['last_name']),
        'first_name': FieldStateToJSON(value['first_name']),
        'patronymic': FieldStateToJSON(value['patronymic']),
    };
}

