/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Поле PUK-кода.
 * @export
 * @interface PukRequest
 */
export interface PukRequest {
    /**
     * 
     * @type {string}
     * @memberof PukRequest
     */
    value: string | null;
}

/**
 * Check if a given object implements the PukRequest interface.
 */
export function instanceOfPukRequest(value: object): value is PukRequest {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function PukRequestFromJSON(json: any): PukRequest {
    return PukRequestFromJSONTyped(json, false);
}

export function PukRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PukRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function PukRequestToJSON(value?: PukRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

