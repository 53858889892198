/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `hidden` - hidden
 * * `optional` - optional
 * * `required` - required
 * @export
 */
export const FieldState = {
    Hidden: 'hidden',
    Optional: 'optional',
    Required: 'required'
} as const;
export type FieldState = typeof FieldState[keyof typeof FieldState];


export function instanceOfFieldState(value: any): boolean {
    for (const key in FieldState) {
        if (Object.prototype.hasOwnProperty.call(FieldState, key)) {
            if ((FieldState as Record<string, FieldState>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FieldStateFromJSON(json: any): FieldState {
    return FieldStateFromJSONTyped(json, false);
}

export function FieldStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldState {
    return json as FieldState;
}

export function FieldStateToJSON(value?: FieldState | null): any {
    return value as any;
}

