import { useMediaQuery } from "@mantine/hooks";
import { Provider } from "../client";
import { cardBreakpoint } from "./FormStepCard";
import classes from "./FormStepCard.module.css";
import SelectedProviderCard from "./SelectedProviderCard";
import classnames from "classnames";
import FormStepperSkeleton from "./FormStepperSkeleton";
import { Skeleton, Stack, rem } from "@mantine/core";

interface Props {
  provider: Provider,
}

export default function FormStepCardSkeleton({provider}: Props) {
  const isMobile = useMediaQuery(`(max-width: ${cardBreakpoint}rem)`);
  const classNames = [classes.root];
  if (isMobile) {
    classNames.push(classes["root--mobile"]);
  } else {
    classNames.push(classes["root--desktop"]);
  }

  return (
    <SelectedProviderCard provider={provider} switchLink variant="compact" classNames={{root: classNames.join(" ")}}>
      <div className={classnames(classes["column"], classes["column--map"])}>
        <FormStepperSkeleton steps={5}/>
      </div>
      <div className={classnames(classes["column"], classes["column--content"])}>
        <Skeleton width={260} height={22} mb={rem(16)}/>
        <Stack mb={rem(20)}>
          <Skeleton height={60}/>
          <Skeleton height={60}/>
        </Stack>
        <Skeleton width={81} height={36}/>
      </div>
    </SelectedProviderCard>
  );
}
