/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StreetType,
  StreetTypesListErrorResponse400,
} from '../models/index';
import {
    StreetTypeFromJSON,
    StreetTypeToJSON,
    StreetTypesListErrorResponse400FromJSON,
    StreetTypesListErrorResponse400ToJSON,
} from '../models/index';

/**
 * 
 */
export class StreetTypesApi extends runtime.BaseAPI {

    /**
     * Типы улиц.
     */
    async streetTypesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StreetType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/street-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StreetTypeFromJSON));
    }

    /**
     * Типы улиц.
     */
    async streetTypesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StreetType>> {
        const response = await this.streetTypesListRaw(initOverrides);
        return await response.value();
    }

}
