/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BasicFieldSchema } from './BasicFieldSchema';
import {
    BasicFieldSchemaFromJSON,
    BasicFieldSchemaFromJSONTyped,
    BasicFieldSchemaToJSON,
} from './BasicFieldSchema';
import type { FullNameGroupSchema } from './FullNameGroupSchema';
import {
    FullNameGroupSchemaFromJSON,
    FullNameGroupSchemaFromJSONTyped,
    FullNameGroupSchemaToJSON,
} from './FullNameGroupSchema';
import type { FullAddressGroupSchema } from './FullAddressGroupSchema';
import {
    FullAddressGroupSchemaFromJSON,
    FullAddressGroupSchemaFromJSONTyped,
    FullAddressGroupSchemaToJSON,
} from './FullAddressGroupSchema';
import type { PassportGroupSchema } from './PassportGroupSchema';
import {
    PassportGroupSchemaFromJSON,
    PassportGroupSchemaFromJSONTyped,
    PassportGroupSchemaToJSON,
} from './PassportGroupSchema';

/**
 * Схема формы.
 * @export
 * @interface FormSchema
 */
export interface FormSchema {
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    iccid: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    sim_number: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    puk: BasicFieldSchema | null;
    /**
     * 
     * @type {FullNameGroupSchema}
     * @memberof FormSchema
     */
    full_name_group: FullNameGroupSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    gender: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    birth_date: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    birth_place: BasicFieldSchema | null;
    /**
     * 
     * @type {FullAddressGroupSchema}
     * @memberof FormSchema
     */
    full_address_group: FullAddressGroupSchema | null;
    /**
     * 
     * @type {PassportGroupSchema}
     * @memberof FormSchema
     */
    passport_group: PassportGroupSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    passport_biodata_photo: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    passport_address_photo: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    contact_phone: BasicFieldSchema | null;
    /**
     * 
     * @type {BasicFieldSchema}
     * @memberof FormSchema
     */
    contact_email: BasicFieldSchema | null;
}

/**
 * Check if a given object implements the FormSchema interface.
 */
export function instanceOfFormSchema(value: object): value is FormSchema {
    if (!('iccid' in value) || value['iccid'] === undefined) return false;
    if (!('sim_number' in value) || value['sim_number'] === undefined) return false;
    if (!('puk' in value) || value['puk'] === undefined) return false;
    if (!('full_name_group' in value) || value['full_name_group'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('birth_date' in value) || value['birth_date'] === undefined) return false;
    if (!('birth_place' in value) || value['birth_place'] === undefined) return false;
    if (!('full_address_group' in value) || value['full_address_group'] === undefined) return false;
    if (!('passport_group' in value) || value['passport_group'] === undefined) return false;
    if (!('passport_biodata_photo' in value) || value['passport_biodata_photo'] === undefined) return false;
    if (!('passport_address_photo' in value) || value['passport_address_photo'] === undefined) return false;
    if (!('contact_phone' in value) || value['contact_phone'] === undefined) return false;
    if (!('contact_email' in value) || value['contact_email'] === undefined) return false;
    return true;
}

export function FormSchemaFromJSON(json: any): FormSchema {
    return FormSchemaFromJSONTyped(json, false);
}

export function FormSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'iccid': BasicFieldSchemaFromJSON(json['iccid']),
        'sim_number': BasicFieldSchemaFromJSON(json['sim_number']),
        'puk': BasicFieldSchemaFromJSON(json['puk']),
        'full_name_group': FullNameGroupSchemaFromJSON(json['full_name_group']),
        'gender': BasicFieldSchemaFromJSON(json['gender']),
        'birth_date': BasicFieldSchemaFromJSON(json['birth_date']),
        'birth_place': BasicFieldSchemaFromJSON(json['birth_place']),
        'full_address_group': FullAddressGroupSchemaFromJSON(json['full_address_group']),
        'passport_group': PassportGroupSchemaFromJSON(json['passport_group']),
        'passport_biodata_photo': BasicFieldSchemaFromJSON(json['passport_biodata_photo']),
        'passport_address_photo': BasicFieldSchemaFromJSON(json['passport_address_photo']),
        'contact_phone': BasicFieldSchemaFromJSON(json['contact_phone']),
        'contact_email': BasicFieldSchemaFromJSON(json['contact_email']),
    };
}

export function FormSchemaToJSON(value?: FormSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iccid': BasicFieldSchemaToJSON(value['iccid']),
        'sim_number': BasicFieldSchemaToJSON(value['sim_number']),
        'puk': BasicFieldSchemaToJSON(value['puk']),
        'full_name_group': FullNameGroupSchemaToJSON(value['full_name_group']),
        'gender': BasicFieldSchemaToJSON(value['gender']),
        'birth_date': BasicFieldSchemaToJSON(value['birth_date']),
        'birth_place': BasicFieldSchemaToJSON(value['birth_place']),
        'full_address_group': FullAddressGroupSchemaToJSON(value['full_address_group']),
        'passport_group': PassportGroupSchemaToJSON(value['passport_group']),
        'passport_biodata_photo': BasicFieldSchemaToJSON(value['passport_biodata_photo']),
        'passport_address_photo': BasicFieldSchemaToJSON(value['passport_address_photo']),
        'contact_phone': BasicFieldSchemaToJSON(value['contact_phone']),
        'contact_email': BasicFieldSchemaToJSON(value['contact_email']),
    };
}

