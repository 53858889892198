import {Button, Center, rem} from '@mantine/core';
import ContentTitle from "../components/ContentTitle.tsx";
import AppLayout from "../components/AppLayout.tsx";
import classes from "./NotFoundPage.module.css";
import { Link } from 'react-router-dom';
import ContentHTML from '../components/ContentHTML.tsx';

export default function NotFoundPage() {
  return (
    <AppLayout>
      <AppLayout.Card variant="content" pt={rem(48)}>
        <Center mb={24}>
          <div className={classes["status-banner"]}>404</div>
        </Center>
        <ContentTitle styles={{root: {textAlign: "center"}}} mb={24}>Страница не найдена</ContentTitle>
        <ContentHTML mb={rem(24)}>
          <p>Здесь такой страницы нет. В ссылке, по которой вы перешли, может быть ошибка.</p>
        </ContentHTML>
        <Button component={Link} variant="outline" to="/" styles={{root: {alignSelf: "flex-start"}}}>На главную</Button>
      </AppLayout.Card>
    </AppLayout>
  );
}
