import { Button, Checkbox, Group, Input, Stack, rem } from "@mantine/core"
import { FormStepKey } from "../core/form/steps";
import { useAppSelector } from "../store";
import { getFormContext } from "../core/form/render-context";
import FormSummary from "./FormSummary";
import { joinBr } from "../utils/react";
import { useState } from "react";
import { getSiteSettings } from "../core/site-settings";
import { isFormErrorsEmpty, isStepFilled } from "../core/form/utils";

export interface Props {
  stepKey: FormStepKey,
  onPrev?: () => void,
  onSubmit?: () => void,
}

export default function ConfirmationStepForm({stepKey, onPrev, onSubmit}: Props) {
  const siteSettings = getSiteSettings();

  const formState = useAppSelector(state => state.form.formState.value!);
  const [termsChecked, setTermsChecked] = useState(false);

  const formRenderer = getFormContext(formState.schema.schema);
  const stepCtx = formRenderer.stepContexts.find(ctx => ctx.step.key === stepKey);
  if (!stepCtx) {
    throw new Error(`${stepKey} not found in form context`);
  }

  const stepState = formState.stepStates[formState.currentStep];
  const isValid = isFormErrorsEmpty(formState.errors);
  const isFilled = isStepFilled(formState.schema.schema, formState.values, stepCtx);

  const errors = [];
  if (stepState.validationFailureMessage) errors.push(stepState.validationFailureMessage);
  if (!isValid) errors.push("Исправьте ошибки выше.");

  return (
    <div>
      <FormSummary mb={rem(16)}/>
      <Stack mb={rem(20)}>
        {stepCtx.fieldContexts.map(fieldCtx => fieldCtx.render())}
        <Checkbox
          checked={termsChecked}
          onChange={event => setTermsChecked(event.target.checked)}
          label={<div dangerouslySetInnerHTML={{__html: siteSettings.terms_checkbox_label || ""}}/>}
        />
      </Stack>
      {errors.length > 0 && (
        <Input.Error mb={rem(20)}>{joinBr(errors)}</Input.Error>
      )}
      <Group gap={rem(8)}>
        <Button variant="outline" disabled={stepState.isValidating} onClick={() => onPrev && onPrev()}>Назад</Button>
        <Button loading={stepState.isValidating} disabled={!isFilled || !termsChecked} onClick={() => onSubmit && onSubmit()}>Отправить заявку</Button>
      </Group>
    </div>
  );
}
