import { RegistrationRequest } from "../../api/types";
import { FieldErrors } from "../errors";
import { FieldSchema, FieldType } from "../model";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.ContactEmail>;
type Values = FieldValues<FieldType.ContactEmail>;
type Errors = FieldErrors<FieldType.ContactEmail>;

export function validateContactEmail(schema: Schema, {value}: Values): Errors {
    if (!value) return [];

  if (!value.includes("@")) {
    return ["Введите действительный email."];
  }

  return [];
}

export function serializeContactEmail(values: Values, request: RegistrationRequest) {
    request.body.contact_email = values;
}
