import {Alert, AlertProps, ElementProps} from "@mantine/core";
import {PropsWithChildren} from "react";
import {IconMoodConfuzed} from "@tabler/icons-react";

interface Props extends AlertProps, ElementProps<"div", keyof AlertProps> {}

export default function ErrorAlert({title, children, ...rest}: PropsWithChildren<Props>) {
  return (
    <Alert variant="light" title={title || "Что-то пошло не так"} color="red" icon={<IconMoodConfuzed/>} {...rest}>{children}</Alert>
  )
}
