import { RegistrationRequest } from "../../api/types";
import { globalFileStorage } from "../../file-storage";
import { FieldType } from "../model";
import { FieldValues } from "../values";

type Values = FieldValues<FieldType.PassportBiodataPhoto>;

export function serializePassportBiodataPhoto(values: Values, request: RegistrationRequest) {
    const fileId = values.fileId;
    const file = fileId ? globalFileStorage.get(fileId) : null;
    if (file) {
        request.body.passport_biodata_photo = {file: true};
        request.passportBiodataPhoto = file;
    } else {
        request.body.passport_biodata_photo = {file: false};
    }
}
