import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  RouterProvider,
} from "react-router-dom";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import {createTheme, DEFAULT_THEME, MantineProvider, rem, Text} from '@mantine/core';
import "./main.css";
import IndexPage from "./pages/IndexPage.tsx";
import store from './store'
import { Provider as ReduxProvider } from 'react-redux'
import RegisterProviderPage, {RegisterProviderLoaderData} from "./pages/RegisterProviderPage.tsx";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {DatesProvider} from "@mantine/dates";
import {ModalsProvider} from "@mantine/modals";
import { NotFoundError } from './core/router.ts';
import RegisterProviderDonePage from './pages/RegisterProviderDonePage.tsx';
import { initSentry } from './core/sentry.ts';
import * as Sentry from "@sentry/react";
import ErrorPage from './pages/ErrorPage.tsx';
import { getSiteSettings } from './core/site-settings.ts';
import RouterErrorElement from './pages/RouterErrorElement.tsx';

initSentry();

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    errorElement: <RouterErrorElement/>,
    children: [
      {
        path: "/",
        element: <IndexPage/>,
      },
      {
        path: "register/:provider/",
        loader: async ({params}: LoaderFunctionArgs): Promise<RegisterProviderLoaderData> => {
          const siteSettings = getSiteSettings();
          const provider = siteSettings.site_providers.map(sp => sp.provider).find(p => p.name === params.provider);
          if (!provider) {
            throw new NotFoundError();
          }
          return {provider};
        },
        element: <RegisterProviderPage/>,
      },
      {
        path: "register/:provider/done/",
        loader: async ({params}: LoaderFunctionArgs): Promise<RegisterProviderLoaderData> => {
          const siteSettings = getSiteSettings();
          const provider = siteSettings.site_providers.map(sp => sp.provider).find(p => p.name === params.provider);
          if (!provider) {
            throw new NotFoundError();
          }
          return {provider};
        },
        element: <RegisterProviderDonePage/>,
      },
    ],
  },
]);

const theme = createTheme({
  fontFamily: `Open Sans, ${DEFAULT_THEME.fontFamily}`,
  components: {
    Text: Text.extend({
      styles: {
        root: {
          fontSize: rem(14),
          lineHeight: rem(22),
        },
      }
    }),
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <DatesProvider settings={{locale: "ru", consistentWeeks: true}}>
            <ReduxProvider store={store}>
              <RouterProvider router={router} />
            </ReduxProvider>
          </DatesProvider>
        </ModalsProvider>
      </MantineProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
