/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `non_field_errors` - non_field_errors
 * * `body.non_field_errors` - body.non_field_errors
 * * `body.iccid.non_field_errors` - body.iccid.non_field_errors
 * * `body.iccid.value` - body.iccid.value
 * * `body.sim_number.non_field_errors` - body.sim_number.non_field_errors
 * * `body.sim_number.value` - body.sim_number.value
 * * `body.puk.non_field_errors` - body.puk.non_field_errors
 * * `body.puk.value` - body.puk.value
 * * `body.full_name_group.non_field_errors` - body.full_name_group.non_field_errors
 * * `body.full_name_group.last_name` - body.full_name_group.last_name
 * * `body.full_name_group.first_name` - body.full_name_group.first_name
 * * `body.full_name_group.patronymic` - body.full_name_group.patronymic
 * * `body.gender.non_field_errors` - body.gender.non_field_errors
 * * `body.gender.choice` - body.gender.choice
 * * `body.birth_date.non_field_errors` - body.birth_date.non_field_errors
 * * `body.birth_date.value` - body.birth_date.value
 * * `body.birth_place.non_field_errors` - body.birth_place.non_field_errors
 * * `body.birth_place.value` - body.birth_place.value
 * * `body.full_address_group.non_field_errors` - body.full_address_group.non_field_errors
 * * `body.full_address_group.country` - body.full_address_group.country
 * * `body.full_address_group.region` - body.full_address_group.region
 * * `body.full_address_group.settlement_type` - body.full_address_group.settlement_type
 * * `body.full_address_group.settlement_name` - body.full_address_group.settlement_name
 * * `body.full_address_group.street_type` - body.full_address_group.street_type
 * * `body.full_address_group.street_name` - body.full_address_group.street_name
 * * `body.full_address_group.house_number` - body.full_address_group.house_number
 * * `body.full_address_group.building_type` - body.full_address_group.building_type
 * * `body.full_address_group.building_number` - body.full_address_group.building_number
 * * `body.full_address_group.postal_code` - body.full_address_group.postal_code
 * * `body.full_address_group.residential_unit_type` - body.full_address_group.residential_unit_type
 * * `body.full_address_group.residential_unit_number` - body.full_address_group.residential_unit_number
 * * `body.passport_group.non_field_errors` - body.passport_group.non_field_errors
 * * `body.passport_group.foreign_citizenship` - body.passport_group.foreign_citizenship
 * * `body.passport_group.citizenship_country` - body.passport_group.citizenship_country
 * * `body.passport_group.series` - body.passport_group.series
 * * `body.passport_group.number` - body.passport_group.number
 * * `body.passport_group.issued_by` - body.passport_group.issued_by
 * * `body.passport_group.issue_date` - body.passport_group.issue_date
 * * `body.passport_group.expiration_date` - body.passport_group.expiration_date
 * * `body.passport_group.subdivision_code` - body.passport_group.subdivision_code
 * * `body.passport_biodata_photo.non_field_errors` - body.passport_biodata_photo.non_field_errors
 * * `body.passport_biodata_photo.file` - body.passport_biodata_photo.file
 * * `body.passport_address_photo.non_field_errors` - body.passport_address_photo.non_field_errors
 * * `body.passport_address_photo.file` - body.passport_address_photo.file
 * * `body.contact_phone.non_field_errors` - body.contact_phone.non_field_errors
 * * `body.contact_phone.value` - body.contact_phone.value
 * * `body.contact_email.non_field_errors` - body.contact_email.non_field_errors
 * * `body.contact_email.value` - body.contact_email.value
 * * `passport_biodata_photo` - passport_biodata_photo
 * * `passport_address_photo` - passport_address_photo
 * @export
 */
export const AttrEnum = {
    NonFieldErrors: 'non_field_errors',
    BodyNonFieldErrors: 'body.non_field_errors',
    BodyIccidNonFieldErrors: 'body.iccid.non_field_errors',
    BodyIccidValue: 'body.iccid.value',
    BodySimNumberNonFieldErrors: 'body.sim_number.non_field_errors',
    BodySimNumberValue: 'body.sim_number.value',
    BodyPukNonFieldErrors: 'body.puk.non_field_errors',
    BodyPukValue: 'body.puk.value',
    BodyFullNameGroupNonFieldErrors: 'body.full_name_group.non_field_errors',
    BodyFullNameGroupLastName: 'body.full_name_group.last_name',
    BodyFullNameGroupFirstName: 'body.full_name_group.first_name',
    BodyFullNameGroupPatronymic: 'body.full_name_group.patronymic',
    BodyGenderNonFieldErrors: 'body.gender.non_field_errors',
    BodyGenderChoice: 'body.gender.choice',
    BodyBirthDateNonFieldErrors: 'body.birth_date.non_field_errors',
    BodyBirthDateValue: 'body.birth_date.value',
    BodyBirthPlaceNonFieldErrors: 'body.birth_place.non_field_errors',
    BodyBirthPlaceValue: 'body.birth_place.value',
    BodyFullAddressGroupNonFieldErrors: 'body.full_address_group.non_field_errors',
    BodyFullAddressGroupCountry: 'body.full_address_group.country',
    BodyFullAddressGroupRegion: 'body.full_address_group.region',
    BodyFullAddressGroupSettlementType: 'body.full_address_group.settlement_type',
    BodyFullAddressGroupSettlementName: 'body.full_address_group.settlement_name',
    BodyFullAddressGroupStreetType: 'body.full_address_group.street_type',
    BodyFullAddressGroupStreetName: 'body.full_address_group.street_name',
    BodyFullAddressGroupHouseNumber: 'body.full_address_group.house_number',
    BodyFullAddressGroupBuildingType: 'body.full_address_group.building_type',
    BodyFullAddressGroupBuildingNumber: 'body.full_address_group.building_number',
    BodyFullAddressGroupPostalCode: 'body.full_address_group.postal_code',
    BodyFullAddressGroupResidentialUnitType: 'body.full_address_group.residential_unit_type',
    BodyFullAddressGroupResidentialUnitNumber: 'body.full_address_group.residential_unit_number',
    BodyPassportGroupNonFieldErrors: 'body.passport_group.non_field_errors',
    BodyPassportGroupForeignCitizenship: 'body.passport_group.foreign_citizenship',
    BodyPassportGroupCitizenshipCountry: 'body.passport_group.citizenship_country',
    BodyPassportGroupSeries: 'body.passport_group.series',
    BodyPassportGroupNumber: 'body.passport_group.number',
    BodyPassportGroupIssuedBy: 'body.passport_group.issued_by',
    BodyPassportGroupIssueDate: 'body.passport_group.issue_date',
    BodyPassportGroupExpirationDate: 'body.passport_group.expiration_date',
    BodyPassportGroupSubdivisionCode: 'body.passport_group.subdivision_code',
    BodyPassportBiodataPhotoNonFieldErrors: 'body.passport_biodata_photo.non_field_errors',
    BodyPassportBiodataPhotoFile: 'body.passport_biodata_photo.file',
    BodyPassportAddressPhotoNonFieldErrors: 'body.passport_address_photo.non_field_errors',
    BodyPassportAddressPhotoFile: 'body.passport_address_photo.file',
    BodyContactPhoneNonFieldErrors: 'body.contact_phone.non_field_errors',
    BodyContactPhoneValue: 'body.contact_phone.value',
    BodyContactEmailNonFieldErrors: 'body.contact_email.non_field_errors',
    BodyContactEmailValue: 'body.contact_email.value',
    PassportBiodataPhoto: 'passport_biodata_photo',
    PassportAddressPhoto: 'passport_address_photo'
} as const;
export type AttrEnum = typeof AttrEnum[keyof typeof AttrEnum];


export function instanceOfAttrEnum(value: any): boolean {
    for (const key in AttrEnum) {
        if (Object.prototype.hasOwnProperty.call(AttrEnum, key)) {
            if ((AttrEnum as Record<string, AttrEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AttrEnumFromJSON(json: any): AttrEnum {
    return AttrEnumFromJSONTyped(json, false);
}

export function AttrEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttrEnum {
    return json as AttrEnum;
}

export function AttrEnumToJSON(value?: AttrEnum | null): any {
    return value as any;
}

