/* tslint:disable */
/* eslint-disable */
/**
 * WF ID
 * API само-регистрации сим-карт
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormSchema } from './FormSchema';
import {
    FormSchemaFromJSON,
    FormSchemaFromJSONTyped,
    FormSchemaToJSON,
} from './FormSchema';

/**
 * Схема формы регистрации.
 * @export
 * @interface RegistrationSchema
 */
export interface RegistrationSchema {
    /**
     * 
     * @type {FormSchema}
     * @memberof RegistrationSchema
     */
    schema: FormSchema;
}

/**
 * Check if a given object implements the RegistrationSchema interface.
 */
export function instanceOfRegistrationSchema(value: object): value is RegistrationSchema {
    if (!('schema' in value) || value['schema'] === undefined) return false;
    return true;
}

export function RegistrationSchemaFromJSON(json: any): RegistrationSchema {
    return RegistrationSchemaFromJSONTyped(json, false);
}

export function RegistrationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'schema': FormSchemaFromJSON(json['schema']),
    };
}

export function RegistrationSchemaToJSON(value?: RegistrationSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'schema': FormSchemaToJSON(value['schema']),
    };
}

