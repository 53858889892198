export type LoadingState = 'notloaded' | 'loading' | 'loaded' | 'failed';

export interface Loadable<T> {
  value: T | null,
  state: LoadingState,
  message?: string,
}

export function newLoadable<T>(initial: T | null = null): Loadable<T> {
  return {value: initial, state: "notloaded"};
}

export function setLoading<T>(l: Loadable<T>) {
  l.state = "loading";
}

export function setLoaded<T>(l: Loadable<T>, value: T) {
  l.value = value;
  l.state = "loaded";
}

export function setFailed<T>(l: Loadable<T>, message: string) {
  l.state = "failed";
  l.message = message;
}

export function isLoaded<T>(l: Loadable<T>): boolean {
  return l.state === "loaded";
}
