import { ErrorResponse } from "react-router-dom";

export class NotFoundError extends Error {
  name = "App:NotFoundError";
}

export function isNotFoundError(err: unknown): err is NotFoundError {
  return err instanceof Error && err.name === "App:NotFoundError";
}

export function isRouterErrorResponse(err: unknown): err is ErrorResponse {
  if (typeof err !== "object" || err === null) {
    return false;
  }

  return (
    "status" in err && typeof err.status === "number" &&
    "statusText" in err && typeof err.statusText === "string" &&
    "data" in err);
}
