import { FieldSchema, FieldType } from "../model";
import { FieldErrors } from "../errors";
import { RegistrationRequest } from "../../api/types";
import { onlyDigits } from "../../../utils/strings";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.Iccid>;
type Values = FieldValues<FieldType.Iccid>;
type Errors = FieldErrors<FieldType.Iccid>;

export function serializeIccid(values: Values, request: RegistrationRequest) {
    request.body.iccid = values;
}

export function validateIccid(schema: Schema, {value}: Values): Errors {
    if (!value) return [];

    const digits = onlyDigits(value);
    if (digits.length < 17) {
      return ["Номер не может быть короче 17 цифр."];
    }
    if (digits.length > 20) {
      return ["Номер не может быть длиннее 20 цифр."];
    }

    return [];
}
