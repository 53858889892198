import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { FormStepKey } from "../../../../core/form/steps";
import { FormErrors } from "../../../../core/form/errors";
import { AsyncThunkConfig } from "../../..";
import { RegistrationApi, RegistrationValidateValidationErrorFromJSON } from "../../../../client";
import { clientConfig } from "../../../../core/api/client";
import { isHTTPError } from "../../../../core/api/errors";
import { keepKeys } from "../../../../utils/data-structures";
import { FormSliceState } from "../reducer";
import { getFormContext, getStepContextAfter } from "../../../../core/form/render-context";
import { clearStepErrors, deserializeFormErrors, emptyFormErrors, fullValidateStep, isFormErrorsEmpty, serializeToRequest } from "../../../../core/form/utils";

export interface ValidateStepPayload {
  stepKey: FormStepKey,
}

const validateStep = createAsyncThunk<Partial<FormErrors>, ValidateStepPayload, AsyncThunkConfig>(
  "form/validateStep",
  async (payload, thunkAPI): Promise<Partial<FormErrors>> => {
    const state = thunkAPI.getState();
    const formState = state.form.formState.value;
    if (!formState) {
      // eslint-disable-next-line no-console
      console.warn("FormState requested by validateStep but is not loaded.");
      return {};
    }

    // Валидируем на клиенте.
    const formContext = getFormContext(formState.schema.schema);
    const stepContext = formContext.stepContexts.find(c => c.step.key === payload.stepKey);
    if (!stepContext) {
      throw new Error(`No step context for key ${payload.stepKey}.`);
    }
    const clientSideErrors = fullValidateStep(formState.schema.schema, formState.values, stepContext);
    if (!isFormErrorsEmpty(clientSideErrors)) {
      return clientSideErrors;
    }

    // Валидируем на сервере.
    const fieldTypes = stepContext.fieldContexts.map(ctx => ctx.type);
    const request = serializeToRequest(formState.provider.name, formState.values, fieldTypes);
    let formErrors = emptyFormErrors();
    const api = new RegistrationApi(clientConfig);
    try {
      await api.registrationValidate(request);
    } catch(e) {
      if (!isHTTPError(e)) {
        throw e;
      }

      const resp = e.asValidationError(RegistrationValidateValidationErrorFromJSON);
      if (!resp) {
        throw e;
      }

      formErrors = deserializeFormErrors(resp);
    }

    return keepKeys(formErrors, fieldTypes);
  },
);
export default validateStep;

export function addValidateStepCases(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addCase(validateStep.pending, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    const stepKey = action.meta.arg.stepKey;
    formState.stepStates[stepKey].isValidating = true;
    formState.stepStates[stepKey].validationFailureMessage = undefined;
    formState.errors = clearStepErrors(formState.errors, stepKey);
  });
  builder.addCase(validateStep.fulfilled, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    const formContext = getFormContext(formState.schema.schema);
    const updatedErrors = action.payload;
    formState.errors = {...formState.errors, ...updatedErrors};

    if (isFormErrorsEmpty(updatedErrors)) {
      const nextStepCtx = getStepContextAfter(formContext, formState.currentStep);
      if (nextStepCtx) {
        formState.currentStep = nextStepCtx.step.key;
      }
    }
  });
  builder.addCase(validateStep.rejected, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    /* eslint-disable-next-line no-console */
    console.error("validateStep rejected", action.error);
    formState.stepStates[action.meta.arg.stepKey].validationFailureMessage = "Что-то пошло не так. Повторите попытку позже.";
  });
}

export function addValidateStepMatchers(builder: ActionReducerMapBuilder<FormSliceState>) {
  builder.addMatcher(validateStep.settled, (state, action) => {
    const formState = state.formState.value;
    if (!formState) return;

    formState.stepStates[action.meta.arg.stepKey].isValidating = false;
  });
}
