import { AttrEnum } from "../../client";
import { AssertKeysIncluded } from "../../utils/types";
import { FieldType } from "./model";

export const ErrorMsg = {
  required: "Обязательное поле.",
};

export interface FormErrors {
  non_field_errors: string[],
  [FieldType.Iccid]: string[],
  [FieldType.SimNumber]: string[],
  [FieldType.Puk]: string[],
  [FieldType.FullNameGroup]: {
    non_field_errors: string[],
    last_name: string[],
    first_name: string[],
    patronymic: string[],
  },
  [FieldType.Gender]: string[],
  [FieldType.BirthDate]: string[],
  [FieldType.BirthPlace]: string[],
  [FieldType.FullAddressGroup]: {
    non_field_errors: string[],
    country: string[],
    region: string[],
    settlement_type: string[],
    settlement_name: string[],
    street_type: string[],
    street_name: string[],
    house_number: string[],
    building_type: string[],
    building_number: string[],
    postal_code: string[],
    residential_unit_type: string[],
    residential_unit_number: string[],
  },
  [FieldType.PassportGroup]: {
    non_field_errors: string[],
    foreign_citizenship: string[],
    citizenship_country: string[],
    series: string[],
    number: string[],
    issued_by: string[],
    issue_date: string[],
    expiration_date: string[],
    subdivision_code: string[],
  },
  [FieldType.PassportBiodataPhoto]: string[],
  [FieldType.PassportAddressPhoto]: string[],
  [FieldType.ContactPhone]: string[],
  [FieldType.ContactEmail]: string[],
}
export type FieldErrors<K extends FieldType> = FormErrors[K];

// Требуем, чтобы в FormErrors были все ключи FieldType
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
type MatchesKeys = AssertKeysIncluded<Record<FieldType, unknown>, FormErrors>;

export const ErrorDeserializers: Record<AttrEnum, (errors: FormErrors) => string[]> = {
  non_field_errors: errors => errors.non_field_errors,
  ["body.non_field_errors"]: errors => errors.non_field_errors,

  ["body.iccid.non_field_errors"]: errors => errors.iccid,
  ["body.iccid.value"]: errors => errors.iccid,

  ["body.sim_number.non_field_errors"]: errors => errors.sim_number,
  ["body.sim_number.value"]: errors => errors.sim_number,

  ["body.puk.non_field_errors"]: errors => errors.puk,
  ["body.puk.value"]: errors => errors.puk,

  ["body.full_name_group.non_field_errors"]: errors => errors.full_name_group.non_field_errors,
  ["body.full_name_group.last_name"]: errors => errors.full_name_group.last_name,
  ["body.full_name_group.first_name"]: errors => errors.full_name_group.first_name,
  ["body.full_name_group.patronymic"]: errors => errors.full_name_group.patronymic,

  ["body.gender.non_field_errors"]: errors => errors.gender,
  ["body.gender.choice"]: errors => errors.gender,

  ["body.birth_date.non_field_errors"]: errors => errors.birth_date,
  ["body.birth_date.value"]: errors => errors.birth_date,

  ["body.birth_place.non_field_errors"]: errors => errors.birth_place,
  ["body.birth_place.value"]: errors => errors.birth_place,

  ["body.full_address_group.non_field_errors"]: errors => errors.full_address_group.non_field_errors,
  ["body.full_address_group.country"]: errors => errors.full_address_group.country,
  ["body.full_address_group.region"]: errors => errors.full_address_group.region,
  ["body.full_address_group.settlement_type"]: errors => errors.full_address_group.settlement_type,
  ["body.full_address_group.settlement_name"]: errors => errors.full_address_group.settlement_name,
  ["body.full_address_group.street_type"]: errors => errors.full_address_group.street_type,
  ["body.full_address_group.street_name"]: errors => errors.full_address_group.street_name,
  ["body.full_address_group.house_number"]: errors => errors.full_address_group.house_number,
  ["body.full_address_group.building_type"]: errors => errors.full_address_group.building_type,
  ["body.full_address_group.building_number"]: errors => errors.full_address_group.building_number,
  ["body.full_address_group.postal_code"]: errors => errors.full_address_group.postal_code,
  ["body.full_address_group.residential_unit_type"]: errors => errors.full_address_group.residential_unit_type,
  ["body.full_address_group.residential_unit_number"]: errors => errors.full_address_group.residential_unit_number,

  ["body.passport_group.non_field_errors"]: errors => errors.passport_group.non_field_errors,
  ["body.passport_group.foreign_citizenship"]: errors => errors.passport_group.foreign_citizenship,
  ["body.passport_group.citizenship_country"]: errors => errors.passport_group.citizenship_country,
  ["body.passport_group.series"]: errors => errors.passport_group.series,
  ["body.passport_group.number"]: errors => errors.passport_group.number,
  ["body.passport_group.issued_by"]: errors => errors.passport_group.issued_by,
  ["body.passport_group.issue_date"]: errors => errors.passport_group.issue_date,
  ["body.passport_group.expiration_date"]: errors => errors.passport_group.expiration_date,
  ["body.passport_group.subdivision_code"]: errors => errors.passport_group.subdivision_code,

  ["body.passport_biodata_photo.non_field_errors"]: errors => errors.passport_biodata_photo,
  ["body.passport_biodata_photo.file"]: errors => errors.passport_biodata_photo,
  ["passport_biodata_photo"]: errors => errors.passport_biodata_photo,

  ["body.passport_address_photo.non_field_errors"]: errors => errors.passport_address_photo,
  ["body.passport_address_photo.file"]: errors => errors.passport_address_photo,
  ["passport_address_photo"]: errors => errors.passport_address_photo,

  ["body.contact_phone.non_field_errors"]: errors => errors.contact_phone,
  ["body.contact_phone.value"]: errors => errors.contact_phone,

  ["body.contact_email.non_field_errors"]: errors => errors.contact_email,
  ["body.contact_email.value"]: errors => errors.contact_email,
}
