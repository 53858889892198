import { FormSchema, RegistrationSubmitValidationError, RegistrationValidateValidationError } from "../../client";
import { References } from "../../store/features/form/reducer";
import { enumKeys, keepKeys } from "../../utils/data-structures";
import { RegistrationRequest } from "../api/types";
import { ErrorDeserializers, FormErrors } from "./errors";
import { FieldType, FormModel, SummaryItem } from "./model";
import { StepContext } from "./render-context";
import { FormStepKey } from "./steps";
import { FormValues } from "./values";

export function getFormSchemaFieldTypes(formSchema: FormSchema): FieldType[] {
    return Object.entries(formSchema).filter(entry => !!entry[1]).map(entry => entry[0]) as FieldType[];
}

export function isFieldTypeSupported(type: FieldType): boolean {
    return Object.prototype.hasOwnProperty.call(FormModel, type);
  }

  export function getStepFieldTypes(key: FormStepKey): FieldType[] {
    return Object.values(FormModel).
      filter(model => model.step === key).
      sort((a, b) => a.order - b.order).
      map(model => model.type);
  }

export function emptyFormValues(): FormValues {
    return {
        iccid: FormModel[FieldType.Iccid].emptyValues(),
        sim_number: FormModel[FieldType.SimNumber].emptyValues(),
        puk: FormModel[FieldType.Puk].emptyValues(),
        full_name_group: FormModel[FieldType.FullNameGroup].emptyValues(),
        gender: FormModel[FieldType.Gender].emptyValues(),
        birth_date: FormModel[FieldType.BirthDate].emptyValues(),
        birth_place: FormModel[FieldType.BirthPlace].emptyValues(),
        full_address_group: FormModel[FieldType.FullAddressGroup].emptyValues(),
        passport_group: FormModel[FieldType.PassportGroup].emptyValues(),
        passport_biodata_photo: FormModel[FieldType.PassportBiodataPhoto].emptyValues(),
        passport_address_photo: FormModel[FieldType.PassportAddressPhoto].emptyValues(),
        contact_phone: FormModel[FieldType.ContactPhone].emptyValues(),
        contact_email: FormModel[FieldType.ContactEmail].emptyValues(),
    };
}

export function emptyFormErrors(): FormErrors {
    return {
        non_field_errors: [],
        iccid: FormModel[FieldType.Iccid].emptyErrors(),
        sim_number: FormModel[FieldType.SimNumber].emptyErrors(),
        puk: FormModel[FieldType.Puk].emptyErrors(),
        full_name_group: FormModel[FieldType.FullNameGroup].emptyErrors(),
        gender: FormModel[FieldType.Gender].emptyErrors(),
        birth_date: FormModel[FieldType.BirthDate].emptyErrors(),
        birth_place: FormModel[FieldType.BirthPlace].emptyErrors(),
        full_address_group: FormModel[FieldType.FullAddressGroup].emptyErrors(),
        passport_group: FormModel[FieldType.PassportGroup].emptyErrors(),
        passport_biodata_photo: FormModel[FieldType.PassportBiodataPhoto].emptyErrors(),
        passport_address_photo: FormModel[FieldType.PassportAddressPhoto].emptyErrors(),
        contact_phone: FormModel[FieldType.ContactPhone].emptyErrors(),
        contact_email: FormModel[FieldType.ContactEmail].emptyErrors(),
    };
}

export function deserializeFormErrors(apiResponse: RegistrationValidateValidationError | RegistrationSubmitValidationError): FormErrors {
    const formErrors = emptyFormErrors();
    for (const apiAttrErrors of apiResponse.errors) {
        let deserialize = ErrorDeserializers[apiAttrErrors.attr];
        if (!deserialize) {
            // eslint-disable-next-line no-console
            console.warn(`Errors of the attribute ${apiAttrErrors.attr} are not supported!`)
            deserialize = errors => errors.non_field_errors;
        }
        const errors = deserialize(formErrors);
        errors.push(apiAttrErrors.detail);
    }
    return formErrors;
}

export enum FieldErrorsState {
    NoField,
    NoErrors,
    HasErrors,
}

export function getFieldErrorsState<K extends FieldType>(type: K, formErrors: Partial<FormErrors>): FieldErrorsState {
    const model = FormModel[type];
    const fieldErrors = formErrors[type];
    if (!fieldErrors) {
        return FieldErrorsState.NoField;
    }
    if (model.isErrorsEmpty(fieldErrors)) {
        return FieldErrorsState.NoErrors;
    }
    return FieldErrorsState.HasErrors;
}

export function isFormErrorsEmpty(formErrors: Partial<FormErrors>): boolean {
    if (formErrors.non_field_errors?.length) {
        return false;
    }

    for (const fieldType of enumKeys(FieldType).map(k => FieldType[k])) {
        if (getFieldErrorsState(fieldType, formErrors) === FieldErrorsState.HasErrors) {
            return false;
        }
    }

    return true;
}

export function clearStepErrors(errors: FormErrors, stepKey: FormStepKey): FormErrors {
    const noErrors = emptyFormErrors();
    const fieldTypes = getStepFieldTypes(stepKey);
    return {
        ...errors,
        ...keepKeys(noErrors, fieldTypes),
    };
}

export function isStepValid(errors: FormErrors, stepKey: FormStepKey): boolean {
    for (const fieldType of getStepFieldTypes(stepKey)) {
        if (getFieldErrorsState(fieldType, errors) === FieldErrorsState.HasErrors) {
            return false;
        }
    }

    return true;
}

export enum FieldFillState {
    NoField,
    Incomplete,
    Filled,
}

export function getFieldFillState<K extends FieldType>(type: K, schema: FormSchema, values: FormValues): FieldFillState {
    const model = FormModel[type];
    const fieldSchema = schema[type];
    if (!fieldSchema) {
        return FieldFillState.NoField;
    }

    const fieldValues = values[type];
    if (!model.isErrorsEmpty(model.require(fieldSchema, fieldValues))) {
        return FieldFillState.Incomplete;
    }

    return FieldFillState.Filled;
}

export function isStepFilled(schema: FormSchema, values: FormValues, ctx: StepContext): boolean {
    return ctx.fieldContexts.every(c => {
        return (
            getFieldFillState(c.type, schema, values) === FieldFillState.NoField ||
            getFieldFillState(c.type, schema, values) === FieldFillState.Filled
        );
    });
}

export function fullValidateField<K extends FieldType>(schema: FormSchema, values: FormValues, type: K): FormErrors[K] {
    const model = FormModel[type];

    if (!schema[type]) {
        return model.emptyErrors();
    }

    const errors = model.require(schema[type], values[type]);
    if (!model.isErrorsEmpty(errors)) {
        return errors;
    }

    return model.validate(schema[type], values[type]);
}

function fullValidateFieldPartial<K extends FieldType>(schema: FormSchema, values: FormValues, type: K): Partial<FormErrors> {
    return {[type]: fullValidateField(schema, values, type)};
}

export function fullValidateStep(schema: FormSchema, values: FormValues, ctx: StepContext): Partial<FormErrors> {
    let errors: Partial<FormErrors> = {};
    for (const fieldCtx of ctx.fieldContexts) {
        const fieldErrors = fullValidateFieldPartial(schema, values, fieldCtx.type);
        errors = {...errors, ...fieldErrors};
    }
    return errors;
}

export function renderFieldSummary<K extends FieldType>(type: K, formSchema: FormSchema, formValues: FormValues, formErrors: FormErrors, references: References): SummaryItem[] {
    const fieldSchema = formSchema[type];
    if (!fieldSchema) {
        return [];
    }
    return FormModel[type].renderSummary(fieldSchema, formValues[type], formErrors[type], references);
}

export function serializeFieldToRequest<K extends FieldType>(type: K, values: FormValues, request: RegistrationRequest) {
    const model = FormModel[type];
    model.serialize(values[type], request);
}

export function serializeToRequest(providerName: string, values: FormValues, fieldTypes: FieldType[]): RegistrationRequest {
    const request: RegistrationRequest = {providerName, body: {}};
    for (const fieldType of fieldTypes) {
        serializeFieldToRequest(fieldType, values, request);
    }
    return request;
}
