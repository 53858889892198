import { ErrorMsg, FieldErrors } from "../errors";
import { FieldSchema, FieldType, SummaryItem } from "../model";
import { RegistrationRequest } from "../../api/types";
import { FieldValues } from "../values";

type Schema = FieldSchema<FieldType.Gender>;
type Values = FieldValues<FieldType.Gender>;
type Errors = FieldErrors<FieldType.Gender>;

export function emptyGenderValues(): Values {
    return {choice: null};
}

export function requireGender({required}: Schema, {choice}: Values): Errors {
    if (required && !choice) {
        return [ErrorMsg.required];
    }
    return [];
}

export function serializeGender(values: Values, request: RegistrationRequest) {
    request.body.gender = values;
}

export function renderGenderSummary(schema: Schema, values: Values, errors: Errors): SummaryItem[] {
    let display = "";
    if (values.choice === "M") {
        display = "Мужской";
    } else if (values.choice === "F") {
        display = "Женский";
    }
    return [{key: "choice", label: "Пол", value: display, hasErrors: errors.length > 0}];
}
