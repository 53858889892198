import {Input, Select, Stack, TextInput} from "@mantine/core";
import {useFormErrors, useFormValues, useReferences} from "../../store/features/form/hooks.ts";
import {ChangeEvent, useCallback, useEffect} from "react";
import classes from "./FullAddressFieldGroup.module.css";
import { parseIntOr } from "../../utils/serialization.ts";
import { joinBr } from "../../utils/react.tsx";
import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { CountryIso } from "../../core/codes.ts";
import SearchableSelect from "../SearchableSelect.tsx";
import { FieldSchema, FieldType } from "../../core/form/model.ts";
import { Country, FieldState } from "../../client/index.ts";

export interface Props {
  fieldSchema: FieldSchema<FieldType.FullAddressGroup>,
}

const OMIT = "omit";

const ruPostalCodeMaskOptions: MaskitoOptions = {
  mask: /^\d{0,6}$/,
}

export default function FullAddressFieldGroup({fieldSchema}: Props) {
  const [values, setValues] = useFormValues(FieldType.FullAddressGroup);
  const [errors, setErrors] = useFormErrors(FieldType.FullAddressGroup);
  const hasNonFieldErrors = errors.non_field_errors.length > 0;

  const {countries, settlementTypes, streetTypes, buildingTypes, residentialUnitTypes: resUnitTypes} = useReferences();

  useEffect(() => {
    // Значения по-умолчанию
    if (!values.settlement_type && settlementTypes.length > 0) {
      setValues({...values, settlement_type: settlementTypes[0].id});
    }
    if (!values.street_type && streetTypes.length > 0) {
      setValues({...values, street_type: streetTypes[0].id});
    }
  }, [values, setValues, settlementTypes, streetTypes]);

  const countriesOptions = countries.map(c => ({label: c.display_name, value: c.iso_code}));
  const onCountryChange = useCallback((isoCode: string | null) => {
    setValues({...values, country: isoCode, region: null});
    setErrors({...errors, country: [], region: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  let countryOrDefault: Country | undefined;
  if (fieldSchema.country === FieldState.Hidden) {
    countryOrDefault = countries.find(c => c.iso_code === fieldSchema.default_country);
  } else {
    countryOrDefault = countries.find(c => c.iso_code === values.country);
  }
  const isRu = countryOrDefault?.iso_code === CountryIso.RU;

  const regionOptions = countryOrDefault ? countryOrDefault.regions.map(r => ({value: r.name, label: r.display_name})) : [];
  const onRegionChange = useCallback((name: string | null) => {
    setValues({...values, region: name})
    setErrors({...errors, region: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const settlementTypeOptions = settlementTypes.map(type => ({value: type.id.toString(), label: type.name}));
  const onSettlementTypeChange = useCallback((idStr: string | null) => {
    setValues({...values, settlement_type: parseIntOr(idStr, null)});
    setErrors({...errors, settlement_type: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onSettlementNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, settlement_name: event.target.value})
    setErrors({...errors, settlement_name: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const streetTypeOptions = streetTypes.map(type => ({value: type.id.toString(), label: type.name}));
  const onStreetTypeChange = useCallback((idStr: string | null) => {
    setValues({...values, street_type: parseIntOr(idStr, null)});
    setErrors({...errors, street_type: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onStreetNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, street_name: event.target.value})
    setErrors({...errors, street_name: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onHouseNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, house_number: event.target.value})
    setErrors({...errors, house_number: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  let buildingTypeOptions = buildingTypes.map(type => ({value: type.id.toString(), label: type.name}));
  buildingTypeOptions = [{value: OMIT, label: "нет"}, ...buildingTypeOptions];
  const onBuildingTypeChange = useCallback((idStr: string | null) => {
    if (idStr === OMIT) {
      setValues({...values, building_type: null, building_number: ""});
      setErrors({...errors, building_type: [], building_number: [], non_field_errors: []});
      return;
    }

    setValues({...values, building_type: parseIntOr(idStr, null)});
    setErrors({...errors, building_type: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onBuildingNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, building_number: event.target.value})
    setErrors({...errors, building_number: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onPostalCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, postal_code: event.target.value})
    setErrors({...errors, postal_code: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const postalCodeRef = useMaskito({options: isRu ? ruPostalCodeMaskOptions : null});

  let resUnitTypeOptions = resUnitTypes.map(type => ({value: type.id.toString(), label: type.name}));
  resUnitTypeOptions = [{value: OMIT, label: "нет"}, ...resUnitTypeOptions];
  const resUnitType = resUnitTypes.find(t => t.id === values.residential_unit_type);
  const resUnitTypeValue = values.omit_residential_unit ? OMIT : resUnitType?.id.toString() || null;
  const onResUnitTypeChange = useCallback((idStr: string | null) => {
    if (idStr === OMIT) {
      setValues({...values, residential_unit_type: null, residential_unit_number: "", omit_residential_unit: true});
      setErrors({...errors, residential_unit_type: [], residential_unit_number: [], non_field_errors: []});
      return;
    }

    const id = parseIntOr(idStr, null);
    setValues({...values, residential_unit_type: id, omit_residential_unit: false});
    setErrors({...errors, residential_unit_type: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  const onResUnitNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, residential_unit_number: event.target.value});
    setErrors({...errors, residential_unit_number: [], non_field_errors: []});
  }, [values, setValues, errors, setErrors]);

  return (
    <Stack>
      {fieldSchema.country !== FieldState.Hidden &&
        <Select
          label="Страна"
          withAsterisk={fieldSchema.country === FieldState.Required}
          data={countriesOptions}
          value={values.country}
          onChange={onCountryChange}
          placeholder="Выберите"
          error={joinBr(errors.country) || hasNonFieldErrors}
        />
      }
      {fieldSchema.region !== FieldState.Hidden &&
        <SearchableSelect
          label="Область"
          withAsterisk={fieldSchema.region === FieldState.Required}
          data={regionOptions}
          value={values.region}
          onChange={onRegionChange}
          placeholder={countryOrDefault ? "Выберите" : "Выберите страну"}
          allowDeselect
          disabled={!countryOrDefault}
          error={joinBr(errors.region) || hasNonFieldErrors}
        />
      }
      {fieldSchema.settlement !== FieldState.Hidden &&
        <Input.Wrapper
          label="Населенный пункт"
          withAsterisk={fieldSchema.settlement === FieldState.Required}
          error={joinBr([...errors.settlement_type, ...errors.settlement_name]) || hasNonFieldErrors}
        >
          <div className={classes.typedSegment}>
            <Select
              data={settlementTypeOptions}
              value={values.settlement_type?.toString() || null}
              onChange={onSettlementTypeChange}
              error={errors.settlement_type.length > 0 || hasNonFieldErrors}
              comboboxProps={{width: "max-content", position: "bottom-start"}}
            />
            <Input
              placeholder="Название"
              value={values.settlement_name || ""}
              onChange={onSettlementNameChange}
              error={errors.settlement_name.length > 0 || hasNonFieldErrors}
            />
          </div>
        </Input.Wrapper>
      }
      {fieldSchema.street !== FieldState.Hidden &&
        <Input.Wrapper
          label="Улица"
          withAsterisk={fieldSchema.street === FieldState.Required}
          error={joinBr([...errors.street_type, ...errors.street_name]) || hasNonFieldErrors}
        >
          <div className={classes.typedSegment}>
            <Select
              data={streetTypeOptions}
              value={values.street_type?.toString() || null}
              onChange={onStreetTypeChange}
              error={errors.street_type.length > 0 || hasNonFieldErrors}
              comboboxProps={{width: "max-content", position: "bottom-start"}}
            />
            <Input
              placeholder="Название"
              value={values.street_name || ""}
              onChange={onStreetNameChange}
              error={errors.street_name.length > 0 || hasNonFieldErrors}
            />
          </div>
        </Input.Wrapper>
      }
      {(fieldSchema.house !== FieldState.Hidden || fieldSchema.building !== FieldState.Hidden) &&
        <div className={classes.houseBuildingRow}>
          {fieldSchema.house !== FieldState.Hidden &&
            <TextInput
              label="Дом"
              withAsterisk={fieldSchema.house === FieldState.Required}
              placeholder="Номер"
              value={values.house_number || ""}
              onChange={onHouseNumberChange}
              error={joinBr(errors.house_number) || hasNonFieldErrors}
              classNames={{root: classes.houseRowItem}}
            />
          }
          {fieldSchema.building !== FieldState.Hidden &&
            <Input.Wrapper
              label="Строение"
              withAsterisk={fieldSchema.building === FieldState.Required}
              error={joinBr([...errors.building_type, ...errors.building_number]) || hasNonFieldErrors}
              classNames={{root: classes.buildingRowItem}}
            >
              <div className={classes.typedSegment}>
                <Select
                  data={buildingTypeOptions}
                  value={values.building_type?.toString() || OMIT}
                  onChange={onBuildingTypeChange}
                  error={errors.building_type.length > 0 || hasNonFieldErrors}
                  comboboxProps={{width: "max-content", position: "bottom-start"}}
                  allowDeselect={false}
                />
                <Input
                  placeholder="Номер"
                  value={values.building_number || ""}
                  onChange={onBuildingNumberChange}
                  error={joinBr(errors.building_number) || hasNonFieldErrors}
                  disabled={!values.building_type}
                />
            </div>
            </Input.Wrapper>
          }
        </div>
      }
      {fieldSchema.postal_code !== FieldState.Hidden &&
        <TextInput
          label="Индекс"
          withAsterisk={fieldSchema.postal_code === FieldState.Required}
          value={values.postal_code || ""}
          onInput={onPostalCodeChange}
          error={joinBr(errors.postal_code) || hasNonFieldErrors}
          ref={postalCodeRef}
        />
      }
      {fieldSchema.residential_unit !== FieldState.Hidden &&
        <Input.Wrapper
          label="Помещение"
          withAsterisk={fieldSchema.residential_unit === FieldState.Required}
          error={joinBr([...errors.residential_unit_type, ...errors.residential_unit_number]) || hasNonFieldErrors}
        >
          <div className={classes.typedSegment}>
            <Select
              data={resUnitTypeOptions}
              value={resUnitTypeValue}
              onChange={onResUnitTypeChange}
              placeholder="тип"
              error={errors.residential_unit_type.length > 0 || hasNonFieldErrors}
              comboboxProps={{width: "max-content", position: "bottom-start"}}
            />
            <Input
              placeholder="Номер"
              disabled={values.omit_residential_unit}
              value={values.omit_residential_unit ? "" : values.residential_unit_number || ""}
              onChange={onResUnitNumberChange}
              error={errors.residential_unit_number.length > 0 || hasNonFieldErrors}
            />
          </div>
        </Input.Wrapper>
      }
      {errors.non_field_errors.length > 0 && (
        <Input.Error>{joinBr(errors.non_field_errors)}</Input.Error>
      )}
    </Stack>
  );
}
